/** @format */

import { alpha } from '@mui/material';
import { DateTime, DurationUnits, Duration } from 'luxon';
import { OrganizationMedicationType } from '../types/SystemMedications.types';
import * as _ from 'underscore';

// Types
import { TimeplanItemsType } from '../types/TitrationCalendar.types';
import { PatientMedicationsPayloadItemType, UptitratePatientMedicaitonPayloadType } from '../types/PatientTCPMedications.types';

const DATE_FORMAT = 'yyyy-MM-dd';

const COLOR_MANAGEMENT = ['#FF0000', '#528AAE', '#00FF00', '#FF6300'];

function dateDiff (date1: string | number, date2: string | number, diffFormat: DurationUnits): Duration {
  return DateTime.fromJSDate(new Date(date1)).diff(DateTime.fromJSDate(new Date(date2)), diffFormat);
}

function increaseDateRange (itemStartDate: number, itemEndDate: number, prevItemEndDate: number) {
  const eleDiffWeeks = dateDiff(itemEndDate, itemStartDate, ['weeks']);
  const newItemStartDate = DateTime.fromJSDate(new Date(prevItemEndDate)).plus({ day: 1 }).startOf('day').toMillis();
  const endTime = DateTime.fromJSDate(new Date(newItemStartDate)).plus({ week: eleDiffWeeks.toObject().weeks }).endOf('day');

  return {
    itemStartDate: newItemStartDate,
    itemEndDate: endTime.toMillis(),
  };
}

function reduceDateRange (itemStartDate: number, modifiedSelectedItemStartDate: number) {
  if (dateDiff(itemStartDate, modifiedSelectedItemStartDate, ['days']).toObject().days === 0) {
    throw new Error('Operation cannot be performed');
  }
  return modifiedSelectedItemStartDate;
}

export function updateCarePlanTimeline (items: TimeplanItemsType[], selectedItemId: number | string, modifiedTimeMilli: number, organizationConfig: OrganizationMedicationType[]) {
  const selectedItem: TimeplanItemsType | undefined = items.find((ele) => ele.id === selectedItemId);

  if (!selectedItem) {
    throw new Error('Selected Item Id doesnot exits');
  }

  const diffTimeWeek: number | undefined = dateDiff(selectedItem?.endTime!, selectedItem?.startTime, ['weeks']).toObject().weeks;

  const modifiedSelectedItemStartDate: DateTime = DateTime.fromMillis(modifiedTimeMilli);
  const modifiedSelectedItemEndDate = DateTime.fromMillis(modifiedTimeMilli).plus({ week: diffTimeWeek }).endOf('day');

  const filterData = items.filter((item) => item.medicationGroup === selectedItem.medicationGroup);
  const unFilterData = items.filter((item) => item.medicationGroup !== selectedItem.medicationGroup);

  const modifiedMap = filterData.map((ele: any, index: number, arr: any) => {
    const data = ele;
    let itemStartDate = ele.startTime;
    let itemEndDate = ele.endTime;

    if (data.id === selectedItem?.id) {
      itemStartDate = modifiedSelectedItemStartDate.toMillis();
      itemEndDate = modifiedSelectedItemEndDate.toMillis();
    }

    if (data.id > selectedItem?.id!) {
      const dateRange = increaseDateRange(ele?.startTime, ele?.endTime, arr[index - 1].endTime);
      itemStartDate = dateRange.itemStartDate;
      itemEndDate = dateRange.itemEndDate;
    }

    if (data.id < selectedItem?.id!) {
      const modifiedIndex = arr.findIndex((i: any) => i.id === selectedItem.id);

      if (modifiedIndex - index === 1) {
        itemEndDate = DateTime.fromMillis(reduceDateRange(itemStartDate, modifiedSelectedItemStartDate.toMillis())).minus({ day: 1 }).endOf('day').toMillis();
      }
    }

    data.startTime = itemStartDate;
    data.endTime = itemEndDate;
    data.startTimeString = DateTime.fromMillis(itemStartDate).toFormat('yyyy-MM-dd');
    data.endTimeString = DateTime.fromMillis(itemEndDate).toFormat('yyyy-MM-dd');

    let diffWeeks = null;

    if (data.endTime) {
      diffWeeks = DateTime.fromMillis(data.endTime).diff(DateTime.fromMillis(data.startTime), ['weeks']).toObject();
      diffWeeks = parseFloat(diffWeeks.weeks! as any).toFixed(1);
    }

    const newDosageLabel = getDosageLabel(data.medicationGroup, data.medicationGroup, data?.dosage!, organizationConfig);

    // TOOTOOTOTO
    data.title = `${newDosageLabel} mg \n${DateTime.fromMillis(data.startTime).toFormat('DD MMM yy')} ${diffWeeks && `Weeks - ${diffWeeks}`}`;

    return { ...data };
  });

  return [...unFilterData, ...modifiedMap].sort((a, b) => a.id - b.id);
}

export function updateCarePlanTimelineByResize (items: TimeplanItemsType[], selectedItemId: string, endTimeOrStartTime: number, edge: string, organizationConfig: OrganizationMedicationType[]) {
  const selectedItem: TimeplanItemsType | undefined = items.find((ele) => ele.id === selectedItemId);

  if (!selectedItem) {
    throw new Error('Selected Item Id doesnot exits');
  }

  let modifiedSelectedItemStartDate: DateTime = DateTime.fromMillis(selectedItem.startTime);
  let modifiedSelectedItemEndDate = DateTime.fromMillis(selectedItem.endTime!);

  if (edge === 'left') {
    modifiedSelectedItemStartDate = DateTime.fromMillis(endTimeOrStartTime);
  } else {
    modifiedSelectedItemEndDate = DateTime.fromMillis(endTimeOrStartTime);
  }

  const modifiedMap = items.map((ele: any, index: number, arr: any) => {
    const data = ele;
    if (data.medicationGroup === selectedItem.medicationGroup) {
      let itemStartDate = ele.startTime;
      let itemEndDate = ele.endTime;

      if (data.id === selectedItem?.id) {
        itemStartDate = modifiedSelectedItemStartDate.startOf('day').toMillis();
        itemEndDate = modifiedSelectedItemEndDate.endOf('day').toMillis();
      }

      if (data.id > selectedItem?.id!) {
        const dateRange = increaseDateRange(ele?.startTime, ele?.endTime, arr[index - 1].endTime);
        itemStartDate = dateRange.itemStartDate;
        itemEndDate = dateRange.itemEndDate;
      }

      if (data.id < selectedItem?.id!) {
        const modifiedIndex = arr.findIndex((i: any) => i.id === selectedItem.id);

        if (modifiedIndex - index === 1) {
          itemEndDate = DateTime.fromMillis(reduceDateRange(itemStartDate, modifiedSelectedItemStartDate.toMillis())).minus({ day: 1 }).endOf('day').toMillis();
        }
      }

      data.startTime = itemStartDate;
      data.endTime = itemEndDate;
      data.startTimeString = DateTime.fromMillis(itemStartDate).toFormat('yyyy-MM-dd');
      data.endTimeString = DateTime.fromMillis(itemEndDate).toFormat('yyyy-MM-dd');

      let diffWeeks = null;

      if (data.endTime) {
        diffWeeks = DateTime.fromMillis(data.endTime).diff(DateTime.fromMillis(data.startTime), ['weeks']).toObject();
        diffWeeks = parseFloat(diffWeeks.weeks! as any).toFixed(1);
      }

      const newDosageLabel = getDosageLabel(data.medicationGroup, data.medicationName, data?.dosage!, organizationConfig);
      // TOOTOOTOTO
      data.title = `${newDosageLabel} mg \n${DateTime.fromMillis(data.startTime).toFormat('DD MMM yy')} ${diffWeeks && `Weeks - ${diffWeeks}`}`;
    }

    return { ...data };
  });

  return modifiedMap;
}

export const getTitraionCalenderPlanItem = (
  prevTime: number,
  startWeek: number,
  endWeek: number,
  medication: any,
  organizationMedication: any,
  index: number,
  colorMedicationGroup: any,
): TimeplanItemsType => {
  let diffWeeks = null;
  if (medication.endTime) {
    diffWeeks = DateTime.fromMillis(prevTime)
      .plus({ day: 1, weeks: startWeek })
      .diff(DateTime.fromMillis(prevTime).plus({ weeks: endWeek, hour: 23, minute: 59, second: 59 }), ['weeks'])
      .toObject();
    diffWeeks = parseFloat(diffWeeks.weeks! as any).toFixed(1);
  }

  return {
    startTimeString: DateTime.fromMillis(prevTime).plus({ day: 1, weeks: startWeek }).toFormat(DATE_FORMAT),
    endTimeString: DateTime.fromMillis(prevTime).plus({ weeks: endWeek, hour: 23, minute: 59, second: 59 }).toFormat(DATE_FORMAT),
    medicationGroup: medication.medicationGroup,
    medicationName: medication.medicationName,
    borderColor: colorMedicationGroup?.color,
    textColor: 'black',
    selectedBgColor: alpha(colorMedicationGroup?.color, index / organizationMedication.dosages.length),
    bgColor: alpha(colorMedicationGroup?.color, index / organizationMedication.dosages.length),
    title: `${organizationMedication.dosages[index]} mg \n${DateTime.fromMillis(prevTime).plus({ day: 1, weeks: startWeek }).toFormat('DD MMM')} ${diffWeeks ? `Weeks - ${diffWeeks}` : ''}`,
    dosage: organizationMedication.dosages[index],
    startTime: DateTime.fromMillis(prevTime).plus({ day: 1, weeks: startWeek }).toMillis(),
    endTime: DateTime.fromMillis(prevTime).plus({ weeks: endWeek, hour: 23, minute: 59, second: 59 }).toMillis(),
    canChangeGroup: false,
    medicationGroupName: `${medication.medicationGroup}-${medication.medicationName}`,
  };
};

export function getItemGroupFromTCP (
  calendarPlan: TimeplanItemsType[],
  colorMedicationGroups: any[],
  organizationConfig: OrganizationMedicationType[],
  medicationOrder?: any[],
  isForceComplitionDate = false,
) {
  const medicationGroupBy = _.groupBy(calendarPlan, 'medicationGroup');
  const items: TimeplanItemsType[] = [];
  let groups: any[] = [];

  let itemCount = 0;

  let medicationIterCount = 0;

  for (const uniqMedicationGroup of Object.keys(medicationGroupBy)) {
    const medications = medicationGroupBy[uniqMedicationGroup];

    const groupByMedicationNames = _.groupBy(medications, 'medicationName');

    for (const uniqMedicationName of Object.keys(groupByMedicationNames)) {
      const medications = groupByMedicationNames[uniqMedicationName];

      let medicationOrderIndex = 0;

      if (medicationOrder) {
        medicationOrderIndex = medicationOrder.findIndex((item) => item.medicationGroup === uniqMedicationGroup);
      }

      const isInActive = medications.some((item) => item?.isInactive);

      groups.push({
        id: `${uniqMedicationGroup}-${uniqMedicationName}`,
        title: `${isInActive ? 'Inactive: ' : ''}${uniqMedicationGroup}`,
        subTitle: uniqMedicationName,
        medicationOrderIndex,
      });

      medications.sort((a, b) => a.startTime - b.startTime);

      for (let i = 0; i < medications.length; i++) {
        let medicationColor = 'red';

        try {
          medicationColor = COLOR_MANAGEMENT[medicationIterCount % COLOR_MANAGEMENT.length];
          medicationColor = alpha(medicationColor, (i + 1) / medications.length);
        } catch (err) {
          console.error('ERROR in calculating the color', err);
        }

        itemCount++;

        const medication = medications[i] || {};

        if (medication?.completionDate || (isForceComplitionDate && 'completionDate' in medication)) {
          medication.endTime = medication.completionDate as any;
        }

        if (!medication.endTime) {
          medication.endTime = DateTime.fromMillis(medication.startTime).plus({ years: 2 }).toMillis();
        }

        const colorMedicationGroup: any = colorMedicationGroups.find((ele: OrganizationMedicationType) => ele.medicationGroup === medication.medicationGroup);

        let diffWeeks = null;

        if (medication.endTime) {
          const diff = DateTime.fromMillis(medication.endTime).diff(DateTime.fromMillis(medication.startTime), ['weeks']).toObject();
          if (diff.weeks && diff.weeks > 0) {
            diffWeeks = parseFloat(diff.weeks as any).toFixed(1);
          }
        }

        const newDosageLabel = getDosageLabel(medication.medicationGroup, medication.medicationName!, medication?.dosage!, organizationConfig);

        if (medication?.dosage!.toString() !== '-2') {
          items.push({
            id: itemCount,
            startTimeString: DateTime.fromMillis(medication.startTime).toFormat(DATE_FORMAT),
            endTimeString: medication.endTime ? DateTime.fromMillis(medication.endTime).toFormat(DATE_FORMAT) : null,
            medicationGroup: medication.medicationGroup,
            medicationName: medication.medicationName,
            medicationGroupName: `${medication.medicationGroup}-${medication.medicationName}`,
            borderColor: colorMedicationGroup?.color,
            textColor: 'black',
            selectedBgColor: medicationColor,
            bgColor: medicationColor,
            title: `${newDosageLabel} mg \n ${DateTime.fromJSDate(new Date(medication.startTime)).toFormat('dd MMM')} ${diffWeeks ? `--  Weeks - ${diffWeeks}` : ''}`,
            dosage: medication.dosage,
            startTime: DateTime.fromMillis(medication.startTime).toMillis(),
            endTime: medication.endTime ? DateTime.fromMillis(medication.endTime).toMillis() : null,
            canChangeGroup: false,
          });
        }
      }

      medicationIterCount++;
    }
  }

  if (medicationOrder) {
    groups = groups.sort((a, b) => a.medicationOrderIndex - b.medicationOrderIndex);
  }

  return { items, groups };
}

export function formatTimingForTCP (calendarPlan: TimeplanItemsType[], endRangeYears = 2.5, organizationConfig: OrganizationMedicationType[]) {
  const medicationGroupBy = _.groupBy(calendarPlan, 'medicationGroup');
  let items: TimeplanItemsType[] = [];
  for (const uniqMedicationGroup of Object.keys(medicationGroupBy)) {
    const medications = medicationGroupBy[uniqMedicationGroup];

    const sortedMedications = medications.sort((a: TimeplanItemsType, b: TimeplanItemsType) => (a.id! as number) - (b.id as number));

    const lastMedicationDosage = sortedMedications[sortedMedications.length - 1];

    const newDosageLabel = getDosageLabel(lastMedicationDosage.medicationGroup, lastMedicationDosage.medicationName!, lastMedicationDosage.dosage!, organizationConfig);

    sortedMedications[sortedMedications.length - 1] = {
      ...lastMedicationDosage,
      endTime: DateTime.fromMillis(lastMedicationDosage.endTime!).plus({ years: endRangeYears }).toMillis(),
      title: `${newDosageLabel} mg \n ${DateTime.fromJSDate(new Date(lastMedicationDosage.startTime)).toFormat('dd MMM')}`,
    };

    items = items.concat(sortedMedications);
  }
  return items;
}

export function generateTCPPlan (medications: UptitratePatientMedicaitonPayloadType[], plans: PatientMedicationsPayloadItemType[], startDate: number) {
  const newPlan: PatientMedicationsPayloadItemType[] = [];

  let newStartDate: number;

  medications.forEach((item: UptitratePatientMedicaitonPayloadType) => {
    const filteredMedicationsPlan = plans.filter((ele) => ele.medicationGroup === item.medicationGroup && ele.medicationName === item.medicationName);
    const filteredSortedMedicationsPlan = filteredMedicationsPlan.sort(
      (a: PatientMedicationsPayloadItemType, b: PatientMedicationsPayloadItemType) => new Date(a.startTime!).getTime() - new Date(b.startTime!).getTime(),
    );

    let prevItemEndDate: number | null = null;

    filteredSortedMedicationsPlan.forEach((item, index: number) => {
      const newPlanItem: any = {
        medicationGroup: item.medicationGroup,
        medicationName: item.medicationName,
        dosage: item.dosage,
      };
      const diffTimeWeek: number | undefined = dateDiff(new Date(item?.endTime!).getTime(), new Date(item?.startTime!).getTime(), ['weeks']).toObject().weeks;
      if (index === 0) {
        newStartDate = startDate;
      }

      let startTime = DateTime.fromJSDate(new Date(newStartDate)).startOf('day');
      if (prevItemEndDate) {
        startTime = DateTime.fromJSDate(new Date(prevItemEndDate)).startOf('day').plus({ day: 1 });
      }

      newPlanItem.startTime = startTime.toMillis();
      newPlanItem.endTime = startTime.plus({ weeks: diffTimeWeek }).toMillis();

      prevItemEndDate = newPlanItem.endTime;

      newPlan.push(newPlanItem);
    });
  });

  return newPlan;
}

export const reGenerateTCPPlanByMedicationChange = (medications: UptitratePatientMedicaitonPayloadType[], plan: PatientMedicationsPayloadItemType[], orgMedication: OrganizationMedicationType[]) => {
  let newTCPPlan: PatientMedicationsPayloadItemType[] = [];

  const planMedications = _.uniq(plan, 'medicationGroup');

  medications.forEach((item: UptitratePatientMedicaitonPayloadType) => {
    let newPlans: PatientMedicationsPayloadItemType[] = plan.filter((e) => item.medicationGroup === e.medicationGroup);
    const isNew = _.isEmpty(planMedications.find((ele) => ele.medicationGroup === item.medicationGroup));
    const dosagesObj = orgMedication.find((ele) => ele.medicationGroup === item.medicationGroup && item.medicationName === ele.medicationName);
    let dosages = dosagesObj?.dosages?.filter((ele: string | number) => ele >= item.dosage!);

    if (parseInt(item.dosage as string) === 0 && dosages) {
      dosages = ['0'].concat(dosages!);
    }

    if (isNew && !item.replaces?.medicationGroup) {
      if (dosages) {
        newPlans = dosages.map((ele, index) => {
          const data: PatientMedicationsPayloadItemType = {
            medicationGroup: item.medicationGroup,
            medicationName: item.medicationName,
            dosage: ele,
            startTime: DateTime.local()
              .plus({ week: index, day: index !== 0 ? 1 : 0 })
              .startOf('day')
              .toMillis(),
            endTime: DateTime.local()
              .plus({ week: index + 1 })
              .endOf('day')
              .toMillis(),
          };
          return data;
        });
      }
    } else {
      if (item.replaces?.medicationGroup) {
        newPlans = plan.filter((e) => item.replaces?.medicationGroup === e.medicationGroup);
      }

      console.log('newPlans ---- ', newPlans);

      newPlans = dosages?.reduce((acc: PatientMedicationsPayloadItemType[], ele: any, index) => {
        if (newPlans && newPlans[index]) {
          console.log('newPlans ---- IF', index, newPlans[index]);

          let endTime = newPlans[index].endTime;

          if ('completionDate' in newPlans[index] && _.isNull(newPlans[index]?.completionDate!)) {
            endTime = DateTime.fromMillis(new Date(newPlans[index]?.startTime!).getTime()).plus({ week: 1 }).endOf('day').toMillis();
          }

          acc.push({
            ...newPlans[index],
            endTime,
            medicationGroup: item.medicationGroup,
            medicationName: item.medicationName,
            dosage: ele,
          });
        } else {
          console.log('newPlans ---- ELSE', index, newPlans[index]);

          let endTime: any = DateTime.fromObject({ minute: 0, second: 0, hour: 0 }).toMillis();
          if (acc[index - 1]) {
            endTime = acc[index - 1].endTime;
          }

          acc.push({
            medicationGroup: item.medicationGroup,
            medicationName: item.medicationName,
            dosage: ele,
            startTime: DateTime.fromMillis(endTime)
              .plus({ day: index !== 0 ? 1 : 0 })
              .startOf('day')
              .toMillis(),
            endTime: DateTime.fromMillis(endTime).plus({ week: 1 }).endOf('day').toMillis(),
          });
        }
        return acc;
      }, [] as PatientMedicationsPayloadItemType[]) as PatientMedicationsPayloadItemType[];
    }

    newTCPPlan = newTCPPlan.concat(newPlans);
  });
  return newTCPPlan;
};

export const getDosageLabel = (medicationGroup: string, medicationName: string, dosage: string | number, organizationConfig: OrganizationMedicationType[]) => {
  let dosageLabel: string | number = dosage;
  if (organizationConfig) {
    const medications = organizationConfig.find((item) => item.medicationGroup === medicationGroup && item.medicationName === medicationName);
    if (!_.isEmpty(medications) && !_.isEmpty(medications?.dosagesLabel)) {
      const label = medications?.dosagesLabel?.find((item) => parseInt(item.value.toString()) === parseInt(dosage as string));
      if (label?.label!) {
        dosageLabel = label?.label!;
      }
    }
  }
  return dosageLabel;
};
