/** @format */

import { DateTime } from 'luxon';

export const getAppointmentEndDate = (payload: { startDate: Date | string; duration: string; timing: string }): { startDate: DateTime; endDate: DateTime } => {
  let startDate: DateTime = DateTime.local();
  let endDate: DateTime = DateTime.local();

  startDate = DateTime.fromISO(payload.startDate as string).plus({
    hours: Number(payload.timing.split(':')[0]),
    minutes: Number(payload.timing.split(':')[1]),
  });

  endDate = startDate.plus({ minute: Number(payload.duration) * 60 - 1 }).set({ second: 59 });

  return { startDate, endDate };
};

export const getStartAndEndDateForAppoitmentWeekView = () => {
  const yearNumber = new Date().getFullYear();
  const weekNumber = DateTime.fromJSDate(new Date()).weekNumber;
  const dt = DateTime.fromObject({
    weekYear: yearNumber,
    weekNumber,
  });

  const dateFromStr = dt.startOf('week');
  const startDate = DateTime.fromISO(dateFromStr.toISO() as string).toFormat('yyyy-MM-dd');
  const dateToStr = dt.endOf('week');
  const endDate = DateTime.fromISO(dateToStr.toISO() as string).toFormat('yyyy-MM-dd');

  return { startDate, endDate };
};

export const combineDateAndTime = (date: Date | string, time: Date): string => {
  const hours = new Date(time).getHours();
  const minutes = new Date(time).getMinutes();

  let newDate: Date | string = new Date();

  if (typeof date === 'string') {
    newDate = DateTime.fromISO(date.toString()).toJSDate();
  } else {
    newDate = date;
  }

  console.log('ScDS>CDS>CDS>C>DSCs', date, newDate);
  newDate = new Date(newDate);

  const dateToModify = DateTime.fromMillis(newDate.setHours(hours, minutes)).toISO() as string;
  return dateToModify;
};

export const formatDateTime = (dateTimeStr: string) => {
  const date = DateTime.fromJSDate(new Date(dateTimeStr)).toFormat('dd/MM/yyyy');
  const time = DateTime.fromJSDate(new Date(dateTimeStr)).toFormat('HH:mm:ss');

  if (date === 'Invalid DateTime' || time === 'Invalid DateTime') {
    return { date: '', time: '' };
  }
  return { date, time };
};
