/** @format */

import React from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Grid, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid #D6D6D6',
    maxWidth: 'none',
    backgroundColor: 'white',
    fontSize: '16px',
    overflow: 'hidden',
  },
});

interface CustomTooltipInterface {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  tooltipTitle: string;
  timestamp: string;
  title: string;
  data: any[];
  tooltipIcon: JSX.Element;
  showAddButton?: boolean;
  totalCount: number;
  handleClickButton?: () => void;
  children: JSX.Element;
}

const CustomTooltip: React.FC<CustomTooltipInterface> = ({ open, onClose, onOpen, tooltipTitle, data, tooltipIcon, showAddButton, totalCount, title, handleClickButton, timestamp, children }) => {
  return (
    <CustomWidthTooltip
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      title={
        <Grid sx={{ maxWidth: 'none' }}>
          <Typography sx={{ color: '#585858' }}>{tooltipTitle}</Typography>

          <Grid sx={{ border: '1px solid #D1D1D1', width: '100%', backgroundColor: '#F8F8F8' }}>
            <Grid
              item
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                marginRight: '5px',
                maxWidth: '500px',
                color: '#646464',
                marginLeft: '5px',
              }}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      }
      placement='bottom'>
      <Grid container xs={12} display='flex' alignItems='center' sx={{ border: '1px solid #e7e7e7', borderRadius: '5px', padding: '8px 5px' }}>
        <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {tooltipIcon}
        </Grid>

        <Grid
          item
          sx={{
            width: 'fit-content',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            marginRight: '5px',
            maxWidth: '500px',
            marginLeft: '5px',
          }}>
          {title}
        </Grid>

        {data.length !== 0 && (
          <Grid item xs={2} sx={{ color: '#a7a7a7' }}>
            ({timestamp})
          </Grid>
        )}

        <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginLeft: 'auto' }} onClick={handleClickButton}>
          {showAddButton ? <AddBoxIcon sx={{ color: '#8e8e8e', marginBottom: '2px' }} /> : null}
        </Grid>

        <Grid sx={{ padding: '2px 5px', borderRadius: '5px', marginLeft: '5px', backgroundColor: '#e7e7e7' }}>{totalCount}</Grid>
      </Grid>
    </CustomWidthTooltip>
  );
};

export default CustomTooltip;
