/** @format */

import { Box, Grid } from '@mui/material';
import { ObjectFieldTemplateProps } from 'react-jsonschema-form';
import CustomCard from '../custom-card';

export const GridObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  const editMode: boolean = props.uiSchema['ui:fields']?.disabled;

  const editCard = props.uiSchema['ui:fields']?.handleClick;

  const customRef = props.uiSchema['ui:fields']?.customRef;

  // ref={props.title.includes('Basic') ? null : props.uiSchema['ui:fields'].ref}
  return (
    <Box ref={customRef} sx={{ '& > div': { pb: '0px' } }}>
      <CustomCard title={props.title} editMode={editMode} handleClickEditCard={editCard}>
        <Grid container spacing={2}>
          {props.properties.map((element, idx) => (
            <Grid item {...element.content?.props?.uiSchema?.['ui:grid']} key={idx}>
              {element.content}
            </Grid>
          ))}
        </Grid>
      </CustomCard>
    </Box>
  );
};

export const GridObjectFieldTemplateWithoutCard = (props: ObjectFieldTemplateProps) => {
  return (
    <Grid container spacing={3}>
      {props.properties.map((element, idx) => (
        <Grid item {...element.content?.props?.uiSchema?.['ui:grid']} key={idx} flex={1}>
          {element.content}
        </Grid>
      ))}
    </Grid>
  );
};

export const GridObjectFieldTemplateWithoutCardColumn = (props: ObjectFieldTemplateProps) => {
  return (
    <Grid container spacing={1} id={props.uiSchema['ui:fields']?.id} sx={{ display: 'flex', flexDirection: 'column' }}>
      {props.properties.map((element, idx) => {
        console.log(' >>> PRRR', element.content?.props);
        return (
          <Grid item {...element.content?.props?.uiSchema?.['ui:grid']} key={idx} flex={1}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
