/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';
import { addDoc, serverTimestamp, collection, query, orderBy, onSnapshot, limit } from 'firebase/firestore';
import { MainContainer, ChatContainer, MessageList, ConversationHeader, MessageSeparator } from '@chatscope/chat-ui-kit-react';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

import ChatViewMessage from './ChatViewMessage';

import { BasicDetailsType } from '../../types/Patients.types';
import { DropdownOptionType } from '../../types/CustomForm.types';
import { ChatMessageItemType, ChatMessagePayloadInterface } from '../../types/ChatsMessage.types';

import { uploadPatientAttachments } from '../../service/patient-attachments';

import { firebaseDB } from '../../firebase-config';
import { getUserSession } from '../../utils/user-session';

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './ChartBox.scss';

interface ChatBoxProps {
  patientBasicInfo: BasicDetailsType;

  userList: DropdownOptionType[];

  handleClose: () => void;
}

const ChatBox: React.FunctionComponent<ChatBoxProps> = (props) => {
  const [chatMessages, setChatMessages] = React.useState<ChatMessagePayloadInterface>({});
  const [message, setMessage] = React.useState<string>('');
  const [loadingMore, setLoadingMore] = React.useState(false);

  const [chatLimit, setChatLimit] = React.useState<number>(10);

  const heightRef = React.useRef<any>(null);

  const patientDisplayName = props?.patientBasicInfo?.firstName ? `${props?.patientBasicInfo?.firstName} ${props?.patientBasicInfo?.lastName || ''}` : props.patientBasicInfo?.patientId;

  const currentUser = getUserSession();

  const debouceListMessageRef = React.useRef<any>(null);
  const inputFileRef = React.useRef<any>(null);

  const updateFirebaseData = (payload: ChatMessageItemType) => {
    return addDoc(collection(firebaseDB, 'messages', props.patientBasicInfo?.patientSourceId!, 'group-message'), payload);
  };

  const handleSubmitSendMessage = async (event: any) => {
    event.preventDefault();

    if (message.trim() === '') {
      return;
    }

    try {
      const payload: ChatMessageItemType = {
        text: message,
        timestamp: DateTime.local().toISO()!,
        createdAt: serverTimestamp(),
        userId: currentUser.userId,
        userType: currentUser.userType,
        messageType: 'MESSAGE',
      };

      await updateFirebaseData(payload);
    } catch (err) {
      console.log('error', err);
    }
    setMessage('');
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (['image/png', 'image/gif', 'image/jpeg', 'application/pdf'].includes(file.type)) {
        const data = await uploadPatientAttachments(props.patientBasicInfo?.patientSourceId!, file, 'CHAT');
        try {
          const payload: ChatMessageItemType = {
            text: message,
            timestamp: DateTime.local().toISO()!,
            createdAt: serverTimestamp(),
            userId: currentUser.userId,
            userType: currentUser.userType,
            messageType: 'ATTACHMENTS',
            attachments: {
              attachmentId: data.attachmentId,
              attachmentType: ['image/png', 'image/gif', 'image/jpeg'].includes(file.type) ? 'IMAGE' : 'PDF',
              attachmentName: file.name,
            },
          };

          await updateFirebaseData(payload);
        } catch (err) {
          console.log('error', err);
        }
      }
    }
  };

  const handleClickOnFileAttachment = () => {
    inputFileRef.current.click();
  };

  const getListRef = () => document.querySelector('[data-message-list-container] [data-cs-message-list]');

  const onYReachStart = () => {
    if (loadingMore) {
      return;
    }

    if (heightRef?.current > 0) {
      return;
    }

    setLoadingMore(true);

    const list: any = getListRef();

    if (list) {
      heightRef.current = list.scrollHeight;
    }

    setChatLimit((prev) => prev + 5);
  };

  React.useEffect(() => {
    const list = getListRef();

    if (list) {
      if (heightRef.current !== null && !loadingMore) {
        heightRef.current = 0;
      }
    }
  }, [loadingMore]);

  React.useEffect(() => {
    const q = query(collection(firebaseDB, 'messages', props.patientBasicInfo?.patientSourceId!, 'group-message'), orderBy('createdAt', 'desc'), limit(chatLimit));

    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const fetchedMessages: any[] = [];

      if (debouceListMessageRef.current) {
        clearTimeout(debouceListMessageRef.current);
      }

      QuerySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });

      const sortedMessages = fetchedMessages.sort((a: any, b: any) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

      setChatMessages(_.groupBy(sortedMessages, (item) => DateTime.fromJSDate(new Date(item.timestamp)).toFormat('yyyy-MM-dd')));
      setLoadingMore(false);
    });
    return () => unsubscribe as any;
  }, [chatLimit, props.patientBasicInfo?.patientSourceId]);

  const additionalProps: any = {
    as: 'MessageInput',
  };

  return (
    <div id='chat-box'>
      <MainContainer>
        <ChatContainer>
          <ConversationHeader>
            <ConversationHeader.Content>
              <Grid container gap={2} alignItems='center'>
                <Grid item xs='auto'>
                  <Avatar sx={{ background: '#5C6A90' }}>{patientDisplayName?.slice(0, 1)}</Avatar>
                </Grid>
                <Grid item xs>
                  <Typography component='div' variant='fontSemiBold14' color='#33425B'>
                    {patientDisplayName}
                  </Typography>
                </Grid>
              </Grid>
            </ConversationHeader.Content>
            <ConversationHeader.Actions>
              <IconButton onClick={props.handleClose}>
                <CloseIcon />
              </IconButton>
            </ConversationHeader.Actions>
          </ConversationHeader>

          <MessageList data-message-list-container onYReachStart={onYReachStart} loadingMore={loadingMore}>
            {Object.keys(chatMessages).map((key) => {
              return (
                <React.Fragment key={key}>
                  <MessageSeparator>
                    <Typography variant='fontReg12' sx={{ background: '#EBEFF8', p: 1, borderRadius: '10px', color: '#5C6A90' }}>
                      {DateTime.fromJSDate(new Date(key)).toFormat('dd MMM yyyy')}
                    </Typography>
                  </MessageSeparator>

                  {chatMessages[key].map((item) => {
                    if (item.messageType === 'EVENT') {
                      let message = item.text;

                      if (item.eventType === 'REQUEST_TO_CALL') {
                        message = 'Patient has requested for a call';
                      }

                      return (
                        <MessageSeparator>
                          <Typography variant='fontReg12' sx={{ background: '#EBEFF8', p: 1, borderRadius: '10px', color: '#5C6A90' }}>
                            {message}
                          </Typography>
                        </MessageSeparator>
                      );
                    }

                    return <ChatViewMessage key={item.id} data={item} userList={props.userList} patientBasicInfo={props.patientBasicInfo} />;
                  })}
                </React.Fragment>
              );
            })}
          </MessageList>

          <Grid container {...additionalProps}>
            <form id='send-message' onSubmit={(event) => handleSubmitSendMessage(event)}>
              <Grid item xs={12} container gap={2} alignItems='center'>
                <Grid item xs='auto'>
                  <IconButton onClick={handleClickOnFileAttachment}>
                    <AttachFileOutlinedIcon htmlColor='#9BB4CC' />
                    <input onChange={handleFileChange} type='file' id='file' ref={inputFileRef} style={{ display: 'none' }} accept='image/png, image/gif, image/jpeg, application/pdf, .pdf' />
                  </IconButton>
                </Grid>
                <Grid item xs>
                  <TextField
                    fullWidth
                    label=''
                    placeholder='Write a message ...'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    sx={{
                      '& .MuiInputBase-root': {
                        border: 'none',
                        borderRadius: '10px',
                        '&::hover': {
                          border: 'none',
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs='auto'>
                  <Button type='submit' variant='contained' sx={{ p: '8px 24px 8px 24px', borderRadius: '20px' }}>
                    Send
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default ChatBox;
