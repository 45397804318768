/** @format */

export const TITRATION_KEYS = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title', // key for item div content
  itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
  itemGroupKey: 'medicationGroupName',
  itemTimeStartKey: 'startTime',
  itemTimeEndKey: 'endTime',
};

export const MASTER_COLOR_MEDICATION_GROUP = [
  {
    medicationGroup: 'ACEi',
    color: '#FF0000',
  },
  {
    medicationGroup: 'ARB',
    color: '#FF0000',
  },
  {
    medicationGroup: 'ARNi',
    color: '#FF0000',
  },
  {
    medicationGroup: 'Beta-Blockers',
    color: '#528AAE',
  },
  {
    medicationGroup: 'MRA',
    color: '#00FF00',
  },
  {
    medicationGroup: 'SGLT2i',
    color: '#FF6300',
  },
];

export const MEDICATION_ARNI_LABEL = [
  {
    dosage: 24,
    label: '24 / 26',
  },
  {
    dosage: 49,
    label: '49 / 51',
  },
  {
    dosage: 97,
    label: '97 / 103',
  },
];

export const MEDICATION_GROUP_CHANGE_IN_DAYS_OPTIONS = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
];
