/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import { Button, ClickAwayListener, Popper as MUIPopper, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Grid, { RegularBreakpoints } from '@mui/material/Grid';

import CircleIcon from '@mui/icons-material/Circle';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

import ViewPatientAlerts from './ViewPatientAlerts';
import CustomPopper from '../../components/common/custom-popper';
import PatientAlertsAndVitalsHistory from './PatientAlertsAndVitalsHistory';
import EditLastEDVisit from './../patient-profile/medical-history/EditLastEDVisit';
import EditLastHospitalization from './../patient-profile/medical-history/EditLastHospitalization';
import EditOtherMedicalHistory from './../patient-profile/medical-history/EditOtherMedicalHistory';
import EditMedicalHistoryDialog from './../patient-profile/medical-history/EditMedicalHistoryModal';
import ViewPatientVitalReported from './../patient-profile/medical-history/ViewPatientVitalReported';
import ViewPatientReportedSymptoms from './../patient-profile/medical-history/ViewPatientReportedSymptom';
import EditLastConsultation from './../../components/patient-profile/medical-history/EditLastConsulation';
import ViewPatientVitalWeightReported from './../patient-profile/medical-history/ViewPatientVitalsWeightReported';

import { PatientProfileContext } from '../../pages/patient-profile/PatientProfileContext';

import { getAlertsForRca } from '../../utils/alert-utils';

import { PatientMedicalHistoryPayloadType } from '../../types/PatientMedicalHistory.types';

import { GENDER_GENDER_KEY_MAPPING } from '../../constants/common';

interface BasicMedicalHistoryFieldsType {
  id: string;
  label?: string | JSX.Element;
  noLabel?: boolean;
  value: string | JSX.Element;
  grid: RegularBreakpoints;

  showHistoryToolTip?: boolean;
  historyToolTipTitle?: string;
  historyToolTipValues?: Array<{ date: string; value: string }>;
}

interface PatientHealthOverviewModalType {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;

  patientData?: any;
}

const PatientHealthOverviewModal: React.FunctionComponent<PatientHealthOverviewModalType> = (props) => {
  const appTheme = useTheme();

  const { patientInfo, isPatientMedicalHistoryUpdatingApiCalling, handleSubmitPatientMedicalHistory /* patientMedicationTask */ } = React.useContext(PatientProfileContext);

  const patientName = props.patientData?.basicDetails?.firstName + props.patientData?.basicDetails?.lastName;
  const patientAge = props.patientData?.basicDetails?.age;
  const patientId = props.patientData?.basicDetails?.patientId;
  const patientGender = GENDER_GENDER_KEY_MAPPING[props.patientData?.basicDetails?.gender] || '';

  const [customHistoryToolTipData, setCustomHistoryToolTipData] = React.useState<{ anchorEl: HTMLButtonElement; title: string; data: Array<{ date: string; value: string }> } | null>(null);

  const [isOpenLastConsultationModal, setIsOpenLastConsultationModal] = React.useState<boolean>(false);
  const [isOpenLastHospitalizationModal, setIsOpenLastHospitalizationModal] = React.useState<boolean>(false);
  const [isOpenLastEDVisitModal, setIsOpenLastEDVisitModal] = React.useState<boolean>(false);
  const [isOpenEditMedicalHistoryModal, setIsOpenEditMedicalHistoryModal] = React.useState<boolean>(false);
  const [isOpenEditOtherHistoryLifeStyleModal, setIsOpenEditOtherHistoryLifeStyleModal] = React.useState<boolean>(false);

  const { recentAlerts, previousAlerts } = getAlertsForRca(props.patientData?.tasks);

  const [isOpenAlertsAndVitalsHistory, setOpenAlertsAndVitalsHistory] = React.useState<boolean>(false);
  const handleClickOpenAlertAndVitalsHistory = () => {
    setOpenAlertsAndVitalsHistory((prev) => !prev);
  };

  const toggleHistoryToolTip = (event?: any) => {
    setCustomHistoryToolTipData(event);
  };

  const toggleEditMedicalHistoryModal = () => {
    setIsOpenEditMedicalHistoryModal((prev) => !prev);
  };

  const toggleLastConsultationModal = () => {
    setIsOpenLastConsultationModal((prev) => !prev);
  };

  const toggleLastHospitalizationModal = () => {
    setIsOpenLastHospitalizationModal((prev) => !prev);
  };

  const toggleLastEDVisitModal = () => {
    setIsOpenLastEDVisitModal((prev) => !prev);
  };

  const toggleEditOtherHistoryLifeStyleModal = () => {
    setIsOpenEditOtherHistoryLifeStyleModal((prev) => !prev);
  };

  const _renderBasicMedicalHistoryFields = (item: BasicMedicalHistoryFieldsType, index: number) => {
    return (
      <Grid key={index} item {...item.grid} sx={{ marginRight: '12px' }} mb='12px'>
        {!item.noLabel && (
          <Typography sx={{ pb: 1 }} component='div' color='#3E4685' variant='fontReg14'>
            {item.label}

            {item.showHistoryToolTip && (
              <IconButton
                sx={{ ml: 0.3 }}
                size='small'
                onClick={(event) => {
                  const data = { anchorEl: event.currentTarget, data: item.historyToolTipValues!, title: item.historyToolTipTitle! };
                  setTimeout(() => {
                    setCustomHistoryToolTipData(data);
                  }, 10);
                }}>
                <ListAltOutlinedIcon fontSize='small' htmlColor={'#3E4685'} sx={{ height: '14px', width: '14px' }} />
              </IconButton>
            )}
          </Typography>
        )}

        <Typography component='div' color='#3E4685' variant='fontSemiBold16' sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
          {item.value}
        </Typography>
      </Grid>
    );
  };

  const basicMedicalHistoryFields: BasicMedicalHistoryFieldsType[] = [
    {
      id: 'symptoms',
      noLabel: true,
      value: <ViewPatientReportedSymptoms showTitle title='Symptoms' alignContent='start' showDateAlongWithTitle symptoms={props.patientData.titrationCycleSymptoms || []} />,
      grid: {
        xs: 5.5,
        lg: 5.5,
      },
    },
    {
      id: 'alerts',
      noLabel: true,
      value: <ViewPatientAlerts alerts={[...recentAlerts, ...previousAlerts]} title='Alerts' showTitle={true} />,
      grid: {
        xs: 5.5,
        lg: 5.5,
      },
    },
    {
      id: 'pulse-rate',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={props.patientData.prescriptions?.prescriptions}
          patientBasicDetails={props.patientData.basicDetails}
          latestVitals={props.patientData.latestVitals || []}
          type='HEART_RATE'
          showTitle
          title='Pulse Rate'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),

      grid: {
        md: 5.5,
        xl: 5.5,
      },
    },
    {
      id: 'bp',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={props.patientData.prescriptions?.prescriptions}
          patientBasicDetails={props.patientData.basicDetails}
          latestVitals={props.patientData.latestVitals || []}
          type='BLOOD_PRESSURE'
          showTitle
          title='BP'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),
      grid: {
        md: 5.5,
        xl: 5.5,
      },
    },
    {
      id: 'egfr',
      label: 'eGFR (mEq/l)',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={props.patientData.prescriptions?.prescriptions}
          patientBasicDetails={props.patientData.basicDetails}
          latestVitals={props.patientData.latestVitals || []}
          type='EGFR'
          showTitle
          title='eGFR'
          showUnitWithTitle
          showDateBelowValue
          tooltipPlacement='auto'
          alignContent='start'
        />
      ),
      grid: {
        md: 5.5,
        xl: 5.5,
      },
    },
    {
      id: 'k',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={props.patientData.prescriptions?.prescriptions}
          patientBasicDetails={props.patientData.basicDetails}
          latestVitals={props.patientData.latestVitals || []}
          type='POTASSIUM'
          showTitle
          title='K'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),
      grid: {
        md: 5.5,
        xl: 5.5,
      },
    },
    {
      id: 'weight',
      label: 'Weight (lbs)',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={props.patientData.prescriptions?.prescriptions}
          patientBasicDetails={props.patientData.basicDetails}
          latestVitals={props.patientData.latestVitals || []}
          type='WEIGHT'
          showTitle
          title='Weight'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),
      grid: {
        md: 5.5,
        xl: 5.5,
      },
    },
    {
      id: 'weight-change',
      label: 'Weight Change (lbs)',
      noLabel: true,
      value: (
        <ViewPatientVitalWeightReported
          prescriptions={props.patientData.prescriptions?.prescriptions}
          patientBasicDetails={props.patientData.basicDetails}
          latestVitals={props.patientData.latestVitals || []}
          vitalsReference={props.patientData.medicalHistory?.vitalsReference || []}
          weightChange={props.patientData.weightChange}
          type='WEIGHT'
          showTitle
          title='Weight Change'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),
      grid: {
        md: 5.5,
        xl: 5.5,
      },
    },
  ];

  return (
    <>
      <ClickAwayListener onClickAway={props.handleClose}>
        <MUIPopper
          open={Boolean(props.anchorEl)}
          anchorEl={props.anchorEl}
          placement='bottom-start'
          sx={{
            marginTop: '-1px !important',
            maxWidth: '400px',
            zIndex: 2,
          }}>
          <Grid
            item
            // xs={12}
            container
            // gap={2}
            p='8px'
            sx={{
              backgroundColor: '#FFFFFF',
              borderWidth: '1px 1px 1px 1px',
              borderStyle: 'solid',
              borderColor: '#4587F0',

              // boxShadow: '5px 5px 30px rgba(0, 0, 0, 0.1)',
            }}>
            <Grid item xs={14} container>
              {basicMedicalHistoryFields.map(_renderBasicMedicalHistoryFields)}
            </Grid>

            <Grid item xs={12} container gap={2} justifyContent='flex-end'>
              <Grid item>
                <Button variant='outlined' onClick={handleClickOpenAlertAndVitalsHistory} sx={{ padding: '5px 10px', alignContent: 'center' }}>
                  Go to Vital Graph
                </Button>
              </Grid>
            </Grid>

            {isOpenAlertsAndVitalsHistory && (
              <Modal open={isOpenAlertsAndVitalsHistory} onClose={handleClickOpenAlertAndVitalsHistory} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid container xs={11} sx={{ backgroundColor: '#FFFFFF', border: '1px solid #9BB4CC' }}>
                  <Grid item container gap={2.5} sx={{ borderBottom: '1px solid #9BB4CC', padding: '1rem 1rem 1rem 1rem' }}>
                    <Grid item>
                      <Typography variant='fontSemiBold16' color='#5C6A90'>
                        Alert history for
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant='fontSemiBold16' color='#5C6A90'>
                        Patient ID : {patientId} {patientName} ({patientAge}/{patientGender})
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs>
                    <PatientAlertsAndVitalsHistory showInteractionPanel={true} patientInfo={props.patientData} />
                  </Grid>
                </Grid>
              </Modal>
            )}

            {customHistoryToolTipData?.anchorEl && customHistoryToolTipData?.data && (
              <CustomPopper
                popperProps={{
                  open: Boolean(customHistoryToolTipData?.anchorEl),
                  anchorEl: customHistoryToolTipData?.anchorEl,
                  placement: 'bottom-end',
                }}
                paperProps={{
                  elevation: 5,
                  sx: {
                    minWidth: '22rem',
                    p: 1.5,
                  },
                }}
                onClose={toggleHistoryToolTip}>
                <Typography variant='fontSemiBold14' sx={{ color: appTheme.palette.customColor.text }}>
                  {customHistoryToolTipData?.title}
                </Typography>
                <Box
                  component='div'
                  sx={{
                    background: '#ECEFF4',
                    border: '0.5px solid #C2D3EF',
                    borderRadius: '5px',
                    overflow: 'auto',
                    maxHeight: '10rem',
                    p: 1,
                  }}>
                  {customHistoryToolTipData?.data?.map((item: { date: string; value: string }, index: number) => {
                    const relativeDate = item?.date && DateTime.fromJSDate(new Date(item.date)).toFormat('dd MMM yy');
                    return (
                      <Grid key={index} mt={1} item xs={12} container alignItems='baseline' gap={0.5}>
                        <Grid item xs='auto'>
                          <CircleIcon sx={{ fill: 'black', width: '8px !important', height: '8px !important' }} />
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='fontReg14' color={appTheme.palette.customColor.text} component='div'>
                            {relativeDate}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant='fontReg14' color={appTheme.palette.customColor.text} component='div' sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                            {item.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </CustomPopper>
            )}

            {isOpenEditMedicalHistoryModal && (
              <EditMedicalHistoryDialog
                isOpen={isOpenEditMedicalHistoryModal}
                isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
                patientInfo={patientInfo}
                handleClose={toggleEditMedicalHistoryModal}
                handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
                  await handleSubmitPatientMedicalHistory(payload);
                  toggleEditMedicalHistoryModal();
                }}
              />
            )}

            {isOpenLastHospitalizationModal && (
              <EditLastHospitalization
                isOpen={isOpenLastHospitalizationModal}
                isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
                patientInfo={patientInfo}
                handleClose={toggleLastHospitalizationModal}
                handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
                  await handleSubmitPatientMedicalHistory(payload);
                  toggleLastHospitalizationModal();
                }}
              />
            )}

            {isOpenLastConsultationModal && (
              <EditLastConsultation
                isOpen={isOpenLastConsultationModal}
                isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
                patientInfo={patientInfo}
                handleClose={toggleLastConsultationModal}
                handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
                  await handleSubmitPatientMedicalHistory(payload);
                  toggleLastConsultationModal();
                }}
              />
            )}

            {isOpenLastEDVisitModal && (
              <EditLastEDVisit
                isOpen={isOpenLastEDVisitModal}
                isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
                patientInfo={patientInfo}
                handleClose={toggleLastEDVisitModal}
                handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
                  await handleSubmitPatientMedicalHistory(payload);
                  toggleLastEDVisitModal();
                }}
              />
            )}

            {isOpenEditOtherHistoryLifeStyleModal && (
              <EditOtherMedicalHistory
                isOpen={isOpenEditOtherHistoryLifeStyleModal}
                isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
                patientInfo={patientInfo}
                handleClose={toggleEditOtherHistoryLifeStyleModal}
                handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
                  await handleSubmitPatientMedicalHistory(payload);
                  toggleEditOtherHistoryLifeStyleModal();
                }}
              />
            )}
          </Grid>
        </MUIPopper>
      </ClickAwayListener>
    </>
  );
};

export default PatientHealthOverviewModal;
