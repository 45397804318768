/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime, DurationLikeObject } from 'luxon';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';

import DeleteIcon from '@mui/icons-material/Delete';

import FormFields from '../common/form-fields/FormFields';
import CustomTable from '../common/custom-table/CustomTable';
import { CustomTableInputBaseCell, CustomTableSelectCell } from './CustomTableComponent';

import { CustomFormType } from '../../types/CustomForm.types';
import { CustomTableColumnType } from '../../types/CustomTable.types';
import { PrescriptionGeneratorContext } from './PrescriptionGeneratorContext';
import { InvestigationTestType } from '../../types/PrescriptionsGenerator.types';

import { RADIO_GROUP_INPUT_COMPONENT, TEXT_INPUT_COMPONENT } from '../../constants/PatientEnrollment';
import { CustomObservations } from '../common/custom-observations/CustomObservations';
import { IMAGE_INVESTIGATION_TESTS, LAB_REPORT_TEST } from '../common/charts/rca-resources/constants';
import { FormatOptionLabelMeta } from 'react-select';

interface InvestigationsNextReviewProps {}

const InvestigationsNextReview: React.FunctionComponent<InvestigationsNextReviewProps> = () => {
  const {
    labInvestigationTest,
    labInvestigationTestErrors,
    handleNewLabInvestigationTest,
    handleChangeLabInvestigationTest,
    handleRemoveLabInvestigationTest,

    imageInvestigationTest,
    imageInvestigationTestErrors,
    handleNewImageInvestigationTest,
    handleChangeImageInvestigationTest,
    handleRemoveImageInvestigationTest,

    procedures,
    handleChangeProcedures,

    prescriptionDate,

    nextReviewDate,
    nextReviewDateErrors,
    handleChangeNextReviewDate,

    patientAppointment,
    patientAppointmentErrors,
    handleChangePatientAppointments,
  } = React.useContext(PrescriptionGeneratorContext);

  const [durationFromCurrentToReviewDate, setDurationFromCurrentToReviewDate] = React.useState<DurationLikeObject | null>(null);

  const handleClickDurationFromCurrentToReviewDate = (duration: DurationLikeObject) => {
    setDurationFromCurrentToReviewDate(duration);
    handleChangeNextReviewDate(DateTime.fromISO(prescriptionDate).plus(duration).toISO()!);
  };

  const _renderLabInvestigationTest = React.useMemo(() => {
    const formatOptionLabel = (data: any, _: FormatOptionLabelMeta<any>) => {
      return (
        <Typography component='div'>
          <Typography component='div' variant='fontSemiBold14'>
            {data.label}
          </Typography>
          <Typography component='div' variant='fontReg14'>
            {data.tests?.length > 0 ? `Tests - ${data?.tests?.length}` : ''}
          </Typography>
        </Typography>
      );
    };

    const investigationsColumnDefs: CustomTableColumnType[] = [
      {
        id: 'investigations',
        label: 'Investigations',
        width: '50%',
        minWidth: 500,
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <CustomTableSelectCell
              key={rowIndex}
              isMulti
              formatOptionLabel={formatOptionLabel}
              value={data?.investigationTests}
              menuPortalTarget={document.body}
              isCreateableSelect
              options={LAB_REPORT_TEST.map((item) => ({ label: item.profileName, value: item.profileName, ...item }))}
              isError={!_.isEmpty(labInvestigationTestErrors?.find((item, index: number) => index === rowIndex)?.investigationTests)}
              onChange={(value) => handleChangeLabInvestigationTest(value, 'investigationTests', rowIndex)}
            />
          );
        },
      },
      {
        id: 'notes',
        label: 'Notes',
        width: '10%',
        minWidth: 100,
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <CustomTableInputBaseCell
              key={rowIndex}
              value={data?.notes}
              onChange={(event) => handleChangeLabInvestigationTest(event.target.value, 'notes', rowIndex)}
              error={!_.isEmpty(labInvestigationTestErrors?.find((item, index: number) => index === rowIndex)?.notes)}
            />
          );
        },
      },
      {
        id: 'date',
        label: 'Date',
        minWidth: 100,
        width: '10%',
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <CustomTableInputBaseCell
              key={rowIndex}
              value={data?.date}
              type='date'
              disabled={!_.isEmpty(data?.dayBeforeReviw)}
              onChange={(event) => handleChangeLabInvestigationTest(event.target.value, 'date', rowIndex)}
              error={!_.isEmpty(labInvestigationTestErrors?.find((item, index: number) => index === rowIndex)?.date)}
            />
          );
        },
      },
      {
        id: 'days-before-review',
        label: 'Days Before Review',
        minWidth: 200,
        width: '10%',
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <CustomTableInputBaseCell
              key={rowIndex}
              value={data?.dayBeforeReviw}
              disabled={!_.isEmpty(data?.date)}
              placeholder='Please Enter value'
              onChange={(event) => handleChangeLabInvestigationTest(event.target.value, 'dayBeforeReviw', rowIndex)}
              error={!_.isEmpty(labInvestigationTestErrors?.find((item, index: number) => index === rowIndex)?.dayBeforeReviw)}
            />
          );
        },
      },
      {
        id: 'action',
        label: '',
        minWidth: 50,
        width: '2%',
        cellAlignment: 'center',
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <IconButton key={rowIndex} onClick={() => handleRemoveLabInvestigationTest(rowIndex)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ];

    return (
      <CustomTable
        showAddRow
        addRowBtnLabel='Add Investigations'
        handleAddNewRow={() => handleNewLabInvestigationTest({} as InvestigationTestType)}
        count={labInvestigationTest.length}
        columns={investigationsColumnDefs}
        data={labInvestigationTest}
        noDataMessage='No Lab investagtion'
        dataRowHover={false}
        stickyHeader
        tableSize='small'
      />
    );
  }, [labInvestigationTest, labInvestigationTestErrors, handleChangeLabInvestigationTest, handleRemoveLabInvestigationTest, handleNewLabInvestigationTest]);

  const _renderImageInvestigationTest = React.useMemo(() => {
    const investigationsColumnDefs: CustomTableColumnType[] = [
      {
        id: 'images-other-tests',
        label: 'Imaging / Other tests',
        minWidth: 500,
        width: '60%',
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <CustomTableSelectCell
              key={rowIndex}
              isMulti
              value={data?.investigationTests}
              menuPortalTarget={document.body}
              isCreateableSelect
              options={IMAGE_INVESTIGATION_TESTS.map((item) => ({ label: item, value: item }))}
              onChange={(value) => handleChangeImageInvestigationTest(value, 'investigationTests', rowIndex)}
              isError={!_.isEmpty(imageInvestigationTestErrors?.find((item, index: number) => index === rowIndex)?.investigationTests)}
            />
          );
        },
      },
      {
        id: 'notes',
        label: 'Notes',
        minWidth: 100,
        width: '10%',
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <CustomTableInputBaseCell
              key={rowIndex}
              value={data?.notes}
              onChange={(event) => handleChangeImageInvestigationTest(event.target.value, 'notes', rowIndex)}
              error={!_.isEmpty(imageInvestigationTestErrors?.find((item, index: number) => index === rowIndex)?.notes)}
            />
          );
        },
      },
      {
        id: 'date',
        label: 'Date',
        minWidth: 100,
        width: '10%',
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <CustomTableInputBaseCell
              key={rowIndex}
              value={data?.date}
              type='date'
              disabled={!_.isEmpty(data?.dayBeforeReviw)}
              onChange={(event) => handleChangeImageInvestigationTest(event.target.value, 'date', rowIndex)}
              error={!_.isEmpty(imageInvestigationTestErrors?.find((item, index: number) => index === rowIndex)?.date)}
            />
          );
        },
      },
      {
        id: 'days-before-review',
        label: 'Days Before Review',
        minWidth: 200,
        width: '10%',
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <CustomTableInputBaseCell
              key={rowIndex}
              value={data?.dayBeforeReviw}
              disabled={!_.isEmpty(data?.date)}
              placeholder='Please Enter value'
              onChange={(event) => handleChangeImageInvestigationTest(event.target.value, 'dayBeforeReviw', rowIndex)}
              error={!_.isEmpty(imageInvestigationTestErrors?.find((item, index: number) => index === rowIndex)?.dayBeforeReviw)}
            />
          );
        },
      },
      {
        id: 'action',
        label: '',
        minWidth: 50,
        width: '2%',
        cellAlignment: 'center',
        cellFormatter: (data: InvestigationTestType, rowIndex: number) => {
          return (
            <IconButton key={rowIndex} onClick={() => handleRemoveImageInvestigationTest(rowIndex)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ];

    return (
      <CustomTable
        showAddRow
        addRowBtnLabel='Add Image / Other tests'
        handleAddNewRow={() => handleNewImageInvestigationTest({} as InvestigationTestType)}
        count={imageInvestigationTest.length || 0}
        data={imageInvestigationTest}
        columns={investigationsColumnDefs}
        noDataMessage='No Lab investagtion'
        dataRowHover={false}
        stickyHeader
        tableSize='small'
      />
    );
  }, [imageInvestigationTest, imageInvestigationTestErrors, handleChangeImageInvestigationTest, handleRemoveImageInvestigationTest, handleNewImageInvestigationTest]);

  const nextReviewDateEasyOptions = [
    {
      variant: durationFromCurrentToReviewDate?.day === 1 ? 'outlined' : 'filled',
      label: '1 Day',
      onClick: () => handleClickDurationFromCurrentToReviewDate({ day: 1 }),
    },
    {
      variant: durationFromCurrentToReviewDate?.weeks === 1 ? 'outlined' : 'filled',
      label: '1 Week',
      onClick: () => handleClickDurationFromCurrentToReviewDate({ weeks: 1 }),
    },
    {
      variant: durationFromCurrentToReviewDate?.weeks === 2 ? 'outlined' : 'filled',
      label: '2 Week',
      onClick: () => handleClickDurationFromCurrentToReviewDate({ weeks: 2 }),
    },
    {
      variant: durationFromCurrentToReviewDate?.months === 1 ? 'outlined' : 'filled',
      label: '1 Month',
      onClick: () => handleClickDurationFromCurrentToReviewDate({ months: 1 }),
    },
    {
      variant: durationFromCurrentToReviewDate?.months === 2 ? 'outlined' : 'filled',
      label: '2 Month',
      onClick: () => handleClickDurationFromCurrentToReviewDate({ months: 2 }),
    },
    {
      variant: durationFromCurrentToReviewDate?.months === 3 ? 'outlined' : 'filled',
      label: '3 Month',
      onClick: () => handleClickDurationFromCurrentToReviewDate({ months: 3 }),
    },
  ];

  const appointmentPlaceFormField: CustomFormType = {
    id: 'appointment-place',
    label: '',
    dataTestIdPrefix: 'appointment-place',
    componentType: RADIO_GROUP_INPUT_COMPONENT,
    fullWidth: true,
    input: {
      type: 'date',
      options: [
        {
          label: 'Virtual',
          value: 'VIRTUAL',
        },

        {
          label: 'Physical',
          value: 'PHYSICAL',
        },
      ],
      value: patientAppointment?.appointmentPlace || {},
      errorMessage: patientAppointmentErrors?.appointmentPlace,
      placeholder: '',
      onChange: (event: any) => handleChangePatientAppointments(event.target.value, 'appointmentPlace'),
    },
    noError: true,
  };

  const appointmentTimingFormField: CustomFormType = {
    id: 'appointment-timing',
    label: 'Timing',
    dataTestIdPrefix: 'appointment-timing',
    componentType: TEXT_INPUT_COMPONENT,
    fullWidth: true,
    input: {
      type: 'time',
      value: patientAppointment.appointmentTime,
      onChange: (event: any) => handleChangePatientAppointments(event.target.value, 'appointmentTime'),
      errorMessage: patientAppointmentErrors?.appointmentTime,
    },
    noError: true,
  };

  const appointmentDurationFormField: CustomFormType = {
    id: 'appointment-duration',
    label: 'Durations',
    dataTestIdPrefix: 'appointment-duration',
    componentType: TEXT_INPUT_COMPONENT,
    fullWidth: true,
    input: {
      value: patientAppointment.appointmentDuration,
      onChange: (event: any) => handleChangePatientAppointments(event.target.value, 'appointmentDuration'),
      endAdornment: 'Mins',
      errorMessage: patientAppointmentErrors?.appointmentDuration,
    },
    noError: true,
  };

  return (
    <Grid item xs={12} container gap={3} sx={{ '.custom-table': { '.MuiTableCell-root': { fontFamily: 'Lato' } } }}>
      <Grid item xs={12}>
        {_renderLabInvestigationTest}
      </Grid>

      <Grid item xs={12}>
        {_renderImageInvestigationTest}
      </Grid>

      <Grid item xs={12}>
        <CustomObservations
          observationActionTitle='Add Procedures'
          observationTitle='Procedures'
          options={[]}
          handleChangeObservations={handleChangeProcedures}
          inputs={[]}
          existingObservations={procedures}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#9BB4CC' }} />
      </Grid>

      <Grid item xs={12} container gap={2.5}>
        <Grid item xs='auto'>
          <Typography component='div' variant='fontReg16' color='#3E4685'>
            Next Review Date
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label=''
            fullWidth
            type='date'
            inputProps={{
              min: prescriptionDate,
            }}
            error={!!nextReviewDateErrors}
            helperText={nextReviewDateErrors}
            value={nextReviewDate ? DateTime.fromJSDate(new Date(nextReviewDate)).toISODate() : ''}
            id='next-review-date'
            onChange={(event) => handleChangeNextReviewDate(event.target.value)}
            sx={{
              '& .MuiInputBase-input': {
                p: 1.2,
              },
            }}
          />

          <Grid item xs={12} container gap={0.5} mt={2}>
            {nextReviewDateEasyOptions.map((item: any) => (
              <Chip
                clickable
                variant={item.variant}
                label={item.label}
                onClick={item.onClick}
                sx={{
                  borderRadius: '5px',
                  '& .MuiChip-label': {
                    px: 0.8,
                    py: 0.1,
                  },
                }}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs container gap={1} sx={{ color: '#3E4685', '& .MuiTypography-root': { color: '#3E4685 !important' } }}>
          <Grid item xs={12} container>
            <Grid item xs={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={patientAppointment?.scheduleAppointment}
                    onChange={(event) => {
                      handleChangePatientAppointments(event.target.checked, 'scheduleAppointment');
                      handleChangePatientAppointments('PHYSICAL', 'appointmentPlace');
                    }}
                  />
                }
                label={
                  <Typography component='div' variant='fontReg16' color='#3E4685'>
                    Schedule consultation
                  </Typography>
                }
              />
            </Grid>

            {patientAppointment?.scheduleAppointment && (
              <Grid item xs={5}>
                <FormFields {...appointmentPlaceFormField} />
              </Grid>
            )}
          </Grid>
          {patientAppointment?.scheduleAppointment && (
            <Grid item xs={12} container gap={5}>
              <Grid item xs={3}>
                <FormFields {...appointmentTimingFormField} />
              </Grid>
              <Grid item xs={3}>
                <FormFields {...appointmentDurationFormField} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvestigationsNextReview;
