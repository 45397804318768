/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';

import Grid from '@mui/material/Grid';
import Timeline from '@mui/lab/Timeline';
import Collapse from '@mui/material/Collapse';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BiotechIcon from '@mui/icons-material/Biotech';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import FormFields from '../../common/form-fields/FormFields';

import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

import { PatientTimeLineType } from '../../../types/Timeline.types';

import { addDatePrefixOriginal } from '../../../utils/date-utils';

import MedicationChangeTimeline from '../MedicationChangeTimeline';
import TimelineLabReportItem from './TimelineLabReportItem';

interface TimelinesProps {}

interface MedicationChangeTimelineItemProps {
  data: PatientTimeLineType;
  lastTimelineItem: boolean;

  organiationUsers: Array<{ label: string; value: string }>;
}

const MedicationChangeTimelineItem: React.FunctionComponent<MedicationChangeTimelineItemProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const timestamp: DateTime = DateTime.fromJSDate(new Date(props.data?.timestamp));

  let icon = <ReceiptLongIcon htmlColor='#3E4685' />;

  if (props.data?.type === 'LAB_REPORTS') {
    icon = <BiotechIcon htmlColor='#3E4685' />;
  }

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot sx={{ background: 'transparent', boxShadow: 'none' }}>{icon}</TimelineDot>
        {!props.lastTimelineItem && <TimelineConnector sx={{ background: 'transparent', borderLeft: '2px dashed #D5D5D6' }} />}
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Grid container alignItems='center' justifyContent='space-between' sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', pl: 1 }}>
          <Typography component='div' variant='fontSemiBold14' color='#3E4685'>
            {addDatePrefixOriginal(timestamp.day)} {timestamp.toFormat(' MMM yyyy')}
          </Typography>
          <IconButton onClick={() => setIsOpen((prev) => !prev)} sx={{ ml: 'auto' }}>
            {!isOpen && <AddIcon />}
            {isOpen && <RemoveIcon />}
          </IconButton>
        </Grid>
        <Collapse in={isOpen}>
          {props.data?.type === 'LAB_REPORTS' && <TimelineLabReportItem {...props} />}
          {props.data?.type === 'MEDICATION_CHANGE' && (
            <MedicationChangeTimeline patientSourceId={props.data?.patientSourceId} prescriptions={props.data?.payload?.prescription} organiationUsers={props.organiationUsers} />
          )}
        </Collapse>
      </TimelineContent>
    </TimelineItem>
  );
};

const Timelines: React.FunctionComponent<TimelinesProps> = () => {
  const { patientVitalsHistoryMedicalEvent, noteUsersOptions } = React.useContext(PatientProfileContext);

  const [medicalEventsFilters, setMedicalEventsFilters] = React.useState({ type: 'ALL' });

  const medicalEventsTimelineData = React.useMemo(() => {
    const formattedTimeline = patientVitalsHistoryMedicalEvent.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

    const medicalEventTimeline = formattedTimeline.filter((item) => {
      if (medicalEventsFilters.type !== 'ALL') {
        return item.type === medicalEventsFilters.type;
      }
      return ['MEDICATION_CHANGE', 'LAB_REPORTS'].includes(item.type);
    }, []);

    return medicalEventTimeline.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  }, [patientVitalsHistoryMedicalEvent, medicalEventsFilters.type]);

  return (
    <Grid container>
      <Grid item xs={12} container>
        <Grid
          item
          xs={8}
          sx={{
            ml: 1,
            '& .MuiFormControlLabel-root': {
              marginRight: 1.5,
              '& .MuiButtonBase-root': {
                p: 0.5,
              },
              '& .MuiTypography-root': {
                fontSize: '14px',
                fontWeight: 'normal',
                color: '#3E4685',
              },
            },
          }}>
          <FormFields
            label=''
            id='filter-type'
            componentType='RADIO_GROUP_INPUT_COMPONENT'
            input={{
              options: [
                { label: 'All', value: 'ALL' },
                { label: 'Prescriptions', value: 'MEDICATION_CHANGE' },
                { label: 'Lab Results', value: 'LAB_REPORTS' },
              ],
              value: medicalEventsFilters.type,
              onChange: (event) => setMedicalEventsFilters((prev) => ({ ...prev, type: event.target.value })),
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container>
        {medicalEventsTimelineData?.length <= 0 && (
          <Typography component='div' variant='fontSemiBold16' color='#3E4685' textAlign='center' sx={{ width: '100%', mt: 2 }}>
            No Timeline information to display
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} container>
        {medicalEventsTimelineData?.length > 0 && (
          <Timeline
            sx={{
              p: 0,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}>
            {medicalEventsTimelineData.map((item, index) => {
              if (['MEDICATION_CHANGE', 'LAB_REPORTS'].includes(item.type)) {
                return <MedicationChangeTimelineItem data={item} organiationUsers={noteUsersOptions} lastTimelineItem={index === medicalEventsTimelineData.length - 1} />;
              }
              return null;
            })}
          </Timeline>
        )}
      </Grid>
    </Grid>
  );
};

export default Timelines;
