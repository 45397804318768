/** @format */

import _ from 'underscore';
import { DateTime } from 'luxon';
import { formatPatientVitals } from '../FormatPatientVitals';
import { vitalsUnitConverters } from 'vitals-convertor';
import {
  VITALS_SCR,
  VITALS_BMI,
  VITALS_BNP,
  VITALS_EGFR,
  VITALS_HBA1C,
  VITALS_HEIGHT,
  VITALS_SODIUM,
  VITALS_WEIGHT,
  VITALS_CALCIUM,
  VITALS_GLUCOSE,
  VITALS_CHLORIDE,
  VITALS_NTPROBNP,
  VITALS_POTASSIUM,
  VITALS_HEMATOCRIT,
  VITALS_HEART_RATE,
  VITALS_HEMOGLOBIN,
  VITALS_BICARBONATE,
  VITALS_BLOOD_PRESSURE,
  VITALS_BLOOD_UREA_NITROGEN,
} from '../../constants/PatientVitals';
import { CARDIOVASULCAR_DISEASES_REVERSE_MAP, NON_CARDIOVASULCAR_DISEASES_REVERSE_MAP, SYMPTOMS_REVERSE_KEY_MAP } from '../../constants/PatientEnrollment';

// Types
import { PatientSymptomsInterface } from '../../reducers/patient-symptoms-reducer/PatientSymptomsSlice';

interface FormatFormDataInterface {
  formData: any;
  symptoms: PatientSymptomsInterface[];
  weightUnit: string;
}

export const formatFormData = (payload: FormatFormDataInterface) => {
  const { formData, symptoms, weightUnit } = payload;
  let updatedVitals: any = [];

  const vitals = formData?.medicalHistory?.vitalsReference?.vitals;
  const vitalsDate = formData?.medicalHistory?.vitalsReference?.vitalsReferenceDate;
  const titrationCalendarPlan = formData.carePlan?.titrationCalendarPlan;

  if (titrationCalendarPlan?.nextReviewDate) {
    titrationCalendarPlan.nextReviewDate = DateTime.fromISO(titrationCalendarPlan?.nextReviewDate).toISO();
  }

  if (vitals) {
    let date = new Date(vitalsDate).getTime();

    if (vitalsDate) {
      date = DateTime.fromJSDate(new Date(vitalsDate)).toMillis();
    }

    for (const key in vitals) {
      console.log('sdcklsdcjkdsncjdsckdsnckdsc VITALS', key, vitals);

      if (key === 'bp' && !_.isEmpty(vitals[key])) {
        const res = formatPatientVitals(VITALS_BLOOD_PRESSURE.value, `${vitals[key]?.sbp}/${vitals[key]?.dbp}`, date, VITALS_BLOOD_PRESSURE.unit, VITALS_BLOOD_PRESSURE.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'hr' && vitals[key]) {
        const res = formatPatientVitals(VITALS_HEART_RATE.value, vitals[key], date, VITALS_HEART_RATE.unit, VITALS_HEART_RATE.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'height' && !_.isEmpty(vitals[key])) {
        const res = formatPatientVitals(VITALS_HEIGHT.value, `${vitals[key]?.feet}/${vitals[key]?.inches}`, date, VITALS_HEIGHT.unit, VITALS_HEIGHT.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'weight' && vitals[key]) {
        let res = formatPatientVitals(VITALS_WEIGHT.value, vitals[key], date, VITALS_WEIGHT.unit, VITALS_WEIGHT.value);

        if (weightUnit === 'kgs') {
          res = formatPatientVitals(VITALS_WEIGHT.value, vitals[key], date, 'kgs', VITALS_WEIGHT.value);

          const value = vitalsUnitConverters(JSON.parse(JSON.stringify(res)), 'lbs');

          updatedVitals = [...updatedVitals, value];
        } else {
          updatedVitals = [...updatedVitals, res];
        }
      }

      if (key === 'potassium' && vitals[key]) {
        const res = formatPatientVitals(VITALS_POTASSIUM.value, vitals[key], date, VITALS_POTASSIUM.unit, VITALS_POTASSIUM.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'scr' && vitals[key]) {
        const res = formatPatientVitals(VITALS_SCR.value, vitals[key], date, VITALS_SCR.unit, VITALS_SCR.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'egfr' && vitals[key]) {
        const res = formatPatientVitals(VITALS_EGFR.value, vitals[key], date, VITALS_EGFR.unit, VITALS_EGFR.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'ntprobnp' && vitals[key]) {
        const res = formatPatientVitals(VITALS_NTPROBNP.value, vitals[key], date, VITALS_NTPROBNP.unit, VITALS_NTPROBNP.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'glucose' && vitals[key]) {
        const res = formatPatientVitals(VITALS_GLUCOSE.value, vitals[key], date, VITALS_GLUCOSE.unit, VITALS_GLUCOSE.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'bnp' && vitals[key]) {
        const res = formatPatientVitals(VITALS_BNP.value, vitals[key], date, VITALS_BNP.unit, VITALS_BNP.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'bmi' && vitals[key]) {
        const res = formatPatientVitals(VITALS_BMI.value, vitals[key], date, VITALS_BMI.unit, VITALS_BMI.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'hba1c' && vitals[key]) {
        const res = formatPatientVitals(VITALS_HBA1C.value, vitals[key], date, VITALS_HBA1C.unit, VITALS_HBA1C.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'hemoglobin' && vitals[key]) {
        const res = formatPatientVitals(VITALS_HEMOGLOBIN.value, vitals[key], date, VITALS_HEMOGLOBIN.unit, VITALS_HEMOGLOBIN.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'hematocrit' && vitals[key]) {
        const res = formatPatientVitals(VITALS_HEMATOCRIT.value, vitals[key], date, VITALS_HEMATOCRIT.unit, VITALS_HEMATOCRIT.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'calcium' && vitals[key]) {
        const res = formatPatientVitals(VITALS_CALCIUM.value, vitals[key], date, VITALS_CALCIUM.unit, VITALS_CALCIUM.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'sodium' && vitals[key]) {
        const res = formatPatientVitals(VITALS_SODIUM.value, vitals[key], date, VITALS_SODIUM.unit, VITALS_SODIUM.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'bicarbonate' && vitals[key]) {
        const res = formatPatientVitals(VITALS_BICARBONATE.value, vitals[key], date, VITALS_BICARBONATE.unit, VITALS_BICARBONATE.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'chloride' && vitals[key]) {
        const res = formatPatientVitals(VITALS_CHLORIDE.value, vitals[key], date, VITALS_CHLORIDE.unit, VITALS_CHLORIDE.value);

        updatedVitals = [...updatedVitals, res];
      }

      if (key === 'bloodUreaNitrogen' && vitals[key]) {
        const res = formatPatientVitals(VITALS_BLOOD_UREA_NITROGEN.value, vitals[key], date, VITALS_BLOOD_UREA_NITROGEN.unit, VITALS_BLOOD_UREA_NITROGEN.value);

        updatedVitals = [...updatedVitals, res];
      }
    }
  }

  let otherCardiovascularDiseases = '';
  let cardiovasculardiseases: string[] = [];
  if (formData?.medicalHistory?.diseasesAndSymptoms?.cardiovascularDiseases) {
    for (const key in formData.medicalHistory.diseasesAndSymptoms.cardiovascularDiseases) {
      if (key === 'otherCardiovascularDiseases' && formData.medicalHistory.diseasesAndSymptoms.cardiovascularDiseases.otherCardiovascularDiseases) {
        otherCardiovascularDiseases = formData.medicalHistory.diseasesAndSymptoms.cardiovascularDiseases[key];
      }

      if (formData.medicalHistory.diseasesAndSymptoms.cardiovascularDiseases[key]) {
        cardiovasculardiseases = [...cardiovasculardiseases, CARDIOVASULCAR_DISEASES_REVERSE_MAP[key]];
      }
    }
    cardiovasculardiseases = cardiovasculardiseases.filter((e) => e);
  }

  let otherNonCardiovascularDiseases = '';
  let nonCardiovascularDiseases: string[] = [];

  if (formData?.medicalHistory?.diseasesAndSymptoms?.nonCardiovascularDiseases) {
    for (const key in formData.medicalHistory.diseasesAndSymptoms.nonCardiovascularDiseases) {
      if (key === 'otherNonCardiovascularDiseases' && formData.medicalHistory.diseasesAndSymptoms.nonCardiovascularDiseases.otherNonCardiovascularDiseases) {
        otherNonCardiovascularDiseases = formData.medicalHistory.diseasesAndSymptoms.nonCardiovascularDiseases[key];
      }

      if (formData.medicalHistory.diseasesAndSymptoms.nonCardiovascularDiseases[key]) {
        nonCardiovascularDiseases = [...nonCardiovascularDiseases, NON_CARDIOVASULCAR_DISEASES_REVERSE_MAP[key]];
      }
    }
    nonCardiovascularDiseases = nonCardiovascularDiseases.filter((e) => e);
  }

  const newSymptoms: any[] = [];
  if (formData?.medicalHistory?.diseasesAndSymptoms?.symptoms) {
    const formDataSymptoms = formData.medicalHistory?.diseasesAndSymptoms?.symptoms;

    if (formDataSymptoms.noSymptoms) {
      newSymptoms.push(symptoms.find((e) => e.shortCode === 'NONE'));
    } else {
      for (const key in formDataSymptoms) {
        if (formDataSymptoms[key]) {
          let symptomsObj = symptoms.find((e) => e.shortCode === SYMPTOMS_REVERSE_KEY_MAP[key]);

          if (formDataSymptoms.others && formDataSymptoms.otherSymptoms.length !== 0) {
            const otherSymptoms = symptoms.find((e) => e.category === 'OTHERS');
            symptomsObj = {
              ...otherSymptoms!,
              description: formDataSymptoms.otherSymptoms,
            };
          }

          if (symptomsObj) {
            newSymptoms.push(symptomsObj);
          }
        }
      }
    }

    if (!formDataSymptoms.others) {
      newSymptoms.filter((e) => e.shortCode === 'NONE');
    }
  }

  const formObj = {
    basicDetails: {
      ...formData.patientBasic,
      phoneNumber: formData.patientBasic?.phoneNumber ? `${formData.patientBasic.phoneNumber}` : undefined,
      phoneNumberAlt: formData.patientBasic?.phoneNumberAlt ? `${formData.patientBasic.phoneNumberAlt}` : undefined,
      cardiologistId: formData?.physician.assignPhysician,
    },
    medicalHistory: {
      allergies: formData.medicalHistory.allergies ? formData.medicalHistory.allergies : null,
      // comorbidities: comorbidities.length !== 0 ? (otherComorbidities.length !== 0 ? [...comorbidities, otherComorbidities] : comorbidities) : [],
      symptoms: newSymptoms.map((e) => {
        return {
          category: e.category,
          shortcode: e.shortCode,
          description: e.description,
        };
      }),

      nonCardiovascularDiseases:
        nonCardiovascularDiseases.length !== 0
          ? otherNonCardiovascularDiseases.length !== 0 && formData?.medicalHistory?.diseasesAndSymptoms?.nonCardiovascularDiseases.others
            ? [...nonCardiovascularDiseases, otherNonCardiovascularDiseases]
            : nonCardiovascularDiseases
          : [],

      cardiovascularDiseases:
        cardiovasculardiseases.length !== 0
          ? otherCardiovascularDiseases.length !== 0 && formData?.medicalHistory?.diseasesAndSymptoms?.cardiovascularDiseases.others
            ? [...cardiovasculardiseases, otherCardiovascularDiseases]
            : cardiovasculardiseases
          : [],

      heartFailureMedicalHistory: {
        dateOfHFDiagnosis: formData.medicalHistory?.dateOfHFDiagnosis,
        hfType: formData.medicalHistory?.hfType,
        lastHospitalizationDate: formData.medicalHistory?.lastHospitalizationDate,
        lvef: formData.medicalHistory?.lvef,
        nyhaClass: formData.medicalHistory?.nyhaClass,
      },

      vitalsReference: updatedVitals,
    },

    previousMedications: formData?.previousMedications,
  };

  return formObj;
};

export const convertScrtoEgfr = (payload: { scr: number; gender: string; age: number; race: string }): number => {
  const FEMALE_UNDER_SCR = -0.329;
  const FEMALE_OVER_SCR = -1.209;
  const MALE_UNDER_SCR = -0.411;
  const MALE_OVER_SCR = -1.209;
  const WHITE_MALE_ALPHA = 141;
  const BLACK_MALE_ALPHA = 163;
  const WHITE_FEMALE_ALPHA = 144;
  const BLACK_FEMALE_ALPHA = 166;

  const { scr, gender, age, race } = payload;

  if (race !== 'BLACK') {
    if (gender === 'MALE') {
      if (scr <= 0.9) {
        return WHITE_MALE_ALPHA * Math.pow(scr / 0.9, MALE_UNDER_SCR) * Math.pow(0.993, age);
      } else {
        return WHITE_MALE_ALPHA * Math.pow(scr / 0.9, MALE_OVER_SCR) * Math.pow(0.993, age);
      }
    } else {
      if (scr <= 0.7) {
        return WHITE_FEMALE_ALPHA * Math.pow(scr / 0.7, FEMALE_UNDER_SCR) * Math.pow(0.993, age);
      } else {
        return WHITE_FEMALE_ALPHA * Math.pow(scr / 0.7, FEMALE_OVER_SCR) * Math.pow(0.993, age);
      }
    }
  }

  if (race === 'BLACK') {
    if (gender === 'MALE') {
      if (scr <= 0.9) {
        return BLACK_MALE_ALPHA * Math.pow(scr / 0.9, MALE_UNDER_SCR) * Math.pow(0.993, age);
      } else {
        return BLACK_MALE_ALPHA * Math.pow(scr / 0.9, MALE_OVER_SCR) * Math.pow(0.993, age);
      }
    } else {
      if (scr <= 0.7) {
        return BLACK_FEMALE_ALPHA * Math.pow(scr / 0.7, FEMALE_UNDER_SCR) * Math.pow(0.993, age);
      } else {
        return BLACK_FEMALE_ALPHA * Math.pow(scr / 0.7, FEMALE_OVER_SCR) * Math.pow(0.993, age);
      }
    }
  }

  return 0;
};

export const bmiCalculator = (height: { feet: number; inches: number }, weight: number, weightUnit: string): number => {
  if (weightUnit === 'lbs') {
    const inches = height.feet * 12 + height.inches;
    const result = (weight / inches ** 2) * 703;
    return Number(result.toFixed(1));
  } else if (weightUnit === 'kgs') {
    const kgsToLbs = weight * 2.2;
    const inches = height.feet * 12 + height.inches;
    const result = (kgsToLbs / inches ** 2) * 703;
    return Number(result.toFixed(1));
  }
  return 0;
};

export const awaitForSometime = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('1');
    }, 500);
  });
};
