/** @format */

import { Box, FormControl, FormLabel, OutlinedInput, Typography, useTheme } from '@mui/material';
import { InfoIcon } from '../../../assets/InfoIcon';

export const GridInputField = (props: any) => {
  const appTheme = useTheme();

  const required = props.uiSchema['ui:required'] || false;
  const mandatory = props.uiSchema['ui:mandatory'] || false;

  return (
    <FormControl required={props.required} disabled={props.disabled} sx={{ display: 'flex' }} id={props.id}>
      <FormLabel sx={{ pb: '8px', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='fontSemiBold14' color={appTheme.palette.customColor.pageTitle} component='div' sx={{ marginLeft: '5px', display: 'flex' }}>
            {props.label} {mandatory && <span style={{ marginLeft: '3px' }}>*</span>}
          </Typography>

          {required && (
            <Typography color='error' sx={{ marginLeft: '3px' }}>
              *
            </Typography>
          )}
        </Box>
      </FormLabel>

      <OutlinedInput
        autoFocus={props.uiSchema?.['ui:autoFocus']}
        fullWidth
        inputProps={{
          tabIndex: props.uiSchema?.['ui:tabIndex'],
        }}
        disabled={props.disabled}
        type={props.uiSchema?.['ui:type'] || 'text'}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        placeholder={props.placeholder}
        sx={{ minHeight: '58px' }}
        className={`${props.uiSchema['ui:fields']?.className}`}
        error={!!(props.rawErrors && props.rawErrors.length !== 0)}
        endAdornment={props.uiSchema['ui:endAdornment'] ? <Box sx={{ marginRight: '10px', backgroundColor: props.disabled ? '#F4F4F4' : '#FFFF' }}>{props.uiSchema['ui:endAdornment']}</Box> : null}
      />
    </FormControl>
  );
};

export const FlexInputField = (props: any) => {
  const appTheme = useTheme();

  const required = props.uiSchema['ui:required'] || false;
  const mandatory = props.uiSchema['ui:mandatory'] || false;

  return (
    <FormControl required={props.required} disabled={props.disabled} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} id={props.id}>
      {props.uiSchema['ui:noLabel'] && (
        <FormLabel sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
          <Box sx={{ minHeight: '31px', display: 'flex', flexDirectio: 'column', alignItems: 'center' }}>
            <Typography variant='fontSemiBold16' color={appTheme.palette.customColor.pageTitle} component='div' sx={{ minWidth: '150px', marginLeft: '5px', textAlign: 'start' }}>
              {props.label} {mandatory && <span style={{ marginLeft: '3px' }}>*</span>}
              {required && <span style={{ color: 'red', marginLeft: '3px' }}>*</span>}
              {props.uiSchema['ui:unit'] && (
                <Typography variant='fontReg14' color={appTheme.palette.customColor.pageTitle} component='div' sx={{ minWidth: '150px', textAlign: 'start', marginTop: '4px' }}>
                  ({props.uiSchema['ui:unit']})
                </Typography>
              )}
            </Typography>
          </Box>
        </FormLabel>
      )}

      <OutlinedInput
        autoFocus={props.uiSchema?.['ui:autoFocus']}
        fullWidth
        inputProps={{
          tabIndex: props.uiSchema?.['ui:tabIndex'],
        }}
        disabled={props.disabled || props.readonly}
        type={props.uiSchema?.['ui:type'] || 'text'}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        placeholder={props.placeholder}
        sx={{ minHeight: '58px' }}
        error={!!(props.rawErrors && props.rawErrors.length !== 0)}
        endAdornment={props.uiSchema['ui:endAdornment'] ? <Box sx={{ marginRight: '10px', backgroundColor: props.disabled ? '#F4F4F4' : '#FFFF' }}>{props.uiSchema['ui:endAdornment']}</Box> : null}
      />

      {props.uiSchema['ui:infoIcon'] && (
        <Box sx={{ marginLeft: '10px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <InfoIcon title={props.uiSchema['ui:info']} />
        </Box>
      )}
    </FormControl>
  );
};
