/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';
import { useQuery } from '@tanstack/react-query';
import { Message, Loader } from '@chatscope/chat-ui-kit-react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ViewIcon from '@mui/icons-material/RemoveRedEye';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import PDFViewer from '../common/PDFViewer';

import { BasicDetailsType } from '../../types/Patients.types';
import { DropdownOptionType } from '../../types/CustomForm.types';
import { ChatMessageItemType } from '../../types/ChatsMessage.types';

import { getUserSession } from '../../utils/user-session';
import { fetchPatientAttachmentsPresignedUrl } from '../../service/patient-attachments';

interface ChatViewMessageProps {
  data: ChatMessageItemType;

  userList: DropdownOptionType[];
  patientBasicInfo: BasicDetailsType;
}

const ChatViewMessage: React.FunctionComponent<ChatViewMessageProps> = (props) => {
  const currentUser = getUserSession();

  const [isOpenAttachmentPDF, setIsOpenAttachmentPDF] = React.useState<boolean>(false);

  const handleClosePDFViewer = () => {
    setIsOpenAttachmentPDF(false);
  };

  const attachmentBufferQuery = useQuery({
    queryKey: ['attachmentBuffer', props.data?.attachments?.attachmentId],
    queryFn: async () => {
      if (props.data?.attachments?.attachmentId) {
        const url = await fetchPatientAttachmentsPresignedUrl(props.patientBasicInfo?.patientSourceId!, props.data?.attachments?.attachmentId);
        return url;
      }
      return '';
    },
  });

  let userDisplayName = 'user';
  const patientDisplayName = props?.patientBasicInfo?.firstName ? `${props?.patientBasicInfo?.firstName} ${props?.patientBasicInfo?.lastName || ''}` : props.patientBasicInfo?.patientId;

  if (props.data.userType === 'ORGANIZATION_USER') {
    userDisplayName = props.userList.find((ele) => ele.value === props.data.userId)?.label || 'user';
  } else if (props.data.userType === 'ORGANIZATION_PATIENTS' || props.data.userId === props?.patientBasicInfo.patientSourceId) {
    userDisplayName = patientDisplayName;
  }

  const isPDFMessage = props.data.messageType === 'ATTACHMENTS' && props.data.attachments?.attachmentType !== 'IMAGE';

  const handleClickViewAttachment = () => {
    if (props.data?.attachments?.attachmentId) {
      if (props.data.messageType === 'ATTACHMENTS' && props.data.attachments?.attachmentType !== 'IMAGE') {
        setIsOpenAttachmentPDF(true);
      }
    }
  };

  return (
    <>
      <Message
        key={props.data.timestamp}
        model={{
          message: props.data.text,
          sentTime: props.data.timestamp,
          position: 0,
          direction: props.data.userId !== currentUser.userId ? 'incoming' : 'outgoing',
        }}>
        <Message.Header>
          <Typography variant='fontSemiBold14' color='#33425B'>
            {userDisplayName || 'user'}
          </Typography>
        </Message.Header>

        <Message.CustomContent>
          <Box
            sx={{
              cursor: isPDFMessage ? 'pointer' : '',
            }}>
            {props.data.messageType !== 'ATTACHMENTS' && (
              <Typography component='div' className='cs-message__content--text' variant='fontReg16'>
                {props.data.text}
              </Typography>
            )}

            {props.data.messageType === 'ATTACHMENTS' && attachmentBufferQuery.isPending && (
              <Box sx={{ minHeight: '100px' }}>
                <Loader />
              </Box>
            )}

            {props.data.messageType === 'ATTACHMENTS' && !isPDFMessage && !attachmentBufferQuery.isPending && <img src={attachmentBufferQuery.data} alt='Akane avatar' width='100%' />}

            {isPDFMessage && !attachmentBufferQuery.isPending && (
              <Grid item xs={12} container style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Grid item xs='auto'>
                  <DescriptionOutlinedIcon className='cs-message__content-pdf-icon' sx={{ width: '14px', height: '14px' }} />
                </Grid>
                <Grid item xs>
                  <Typography
                    component='div'
                    className='cs-message__content-pdf-name'
                    variant='fontReg16'
                    style={{
                      marginLeft: '5px',
                      width: '80%',
                      marginRight: '5px',
                      maxWidth: '200px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden !important',
                      textOverflow: 'ellipsis',
                    }}>
                    {props.data.attachments?.attachmentName}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <IconButton onClick={handleClickViewAttachment}>
                    <ViewIcon className='cs-message__content-pdf-icon' sx={{ width: '14px', height: '14px' }} />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            <Typography component='div' className='cs-message__content--time' variant='fontReg12'>
              {DateTime.fromJSDate(new Date(props.data.timestamp)).toFormat('hh:mm a')}
            </Typography>
          </Box>
        </Message.CustomContent>
      </Message>

      {isOpenAttachmentPDF && <PDFViewer isOpen={isOpenAttachmentPDF} handleClose={handleClosePDFViewer} pdfUrl={attachmentBufferQuery?.data} />}
    </>
  );
};

export default ChatViewMessage;
