/** @format */

// Library
import { lazy, Suspense } from 'react';

// Types
import { Outlet, RouteObject } from 'react-router-dom';

// Components
import PrivateAuth from './PrivateAuth';
import EmergencyLogin from './pages/login/EmergencyLogin';
import PatientList from './pages/patient-list/PatientList';
import DraftPatientList from './pages/patient-list/DraftPatientList';
import ActivePatientList from './pages/patient-list/ActivePatientList';
import DeactivePatientList from './pages/patient-list/DeactivePatientList';
import CustomLoading from './components/common/custom-loading/CustomLoading';
import UserPermissionPage from './components/common/user-permission/UserPermissionPage';

import { APP_ROLES_CARE_TEAM, APP_ROLES_DOCTORS } from './constants/user-role';

const Layout = lazy(() => import('./components/layout/Layout'));
const Appointments = lazy(() => import('./pages/appointments/Appointments'));
const DashboardPage = lazy(() => import('./pages/analytics-dashboard/AnalyticsDashboard'));
const HistoryLogs = lazy(() => import('./pages/logs/Logs'));

const WorkspacePage = lazy(() => import('./pages/workspace/Workspace'));
const AdjustMedicationPage = lazy(() => import('./pages/workspace/AdjustMedication'));
const PatientMonitoringPage = lazy(() => import('./pages/workspace/PatientMonitoring'));
const PatientApproval = lazy(() => import('./pages/workspace/PatientApproval'));

const UserSettingPage = lazy(() => import('./pages/users/UserSetting'));

const PatientEnrolmentLazy = lazy(() => import('./pages/patient-enrolment/PatientEnrolmentContext'));

const LoginWithPin = lazy(() => import('./pages/login/LoginWithPin'));
const Login = lazy(() => import('./pages/login/Login'));
const LoginSuccess = lazy(() => import('./pages/login/LoginSuccess'));
const UserResetPassword = lazy(() => import('./pages/login/UserResetPassword'));
const PatientProfile = lazy(() => import('./pages/patient-profile/PatientProfileContext'));

const Page404 = lazy(() => import('./pages/Page404'));
const NoAccessPage = lazy(() => import('./pages/NoAccessPage'));
const PatientResetPassword = lazy(() => import('./pages/patient-reset-password/PatientResetPassword'));

export const NavRoutes: RouteObject[] = [
  {
    path: '/login',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <Login />
      </Suspense>
    ),
  },

  {
    path: '/emergency-login/:emergencyId',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <EmergencyLogin />
      </Suspense>
    ),
  },
  {
    path: '/login-success',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <LoginSuccess />
      </Suspense>
    ),
  },

  {
    path: '/reset/:resetToken/password',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <PatientResetPassword />
      </Suspense>
    ),
  },
  {
    path: '/users/reset/:resetToken/password',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <UserResetPassword />
      </Suspense>
    ),
  },
  {
    path: '/no-access',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <NoAccessPage />
      </Suspense>
    ),
  },
  {
    path: '/login-with-pin',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <LoginWithPin />
      </Suspense>
    ),
  },
  {
    path: '/',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <PrivateAuth>
          <Layout />
        </PrivateAuth>
      </Suspense>
    ),
    children: [
      {
        path: '/workspace',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
              <WorkspacePage />
              <Outlet />
            </UserPermissionPage>
          </Suspense>
        ),
        children: [
          {
            path: 'adjust-medications',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                  <AdjustMedicationPage />
                </UserPermissionPage>
              </Suspense>
            ),
          },
          {
            path: 'monitor-patients',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                  <PatientMonitoringPage />
                </UserPermissionPage>
              </Suspense>
            ),
          },
          {
            path: 'approvals',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                  <PatientApproval />
                </UserPermissionPage>
              </Suspense>
            ),
          },
        ],
      },

      {
        path: '/dashboard',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
              <DashboardPage />
            </UserPermissionPage>
          </Suspense>
        ),
      },
      {
        path: '/users/setting',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <UserPermissionPage roles={[APP_ROLES_DOCTORS]}>
              <UserSettingPage />
            </UserPermissionPage>
          </Suspense>
        ),
      },
      {
        path: '/patients',
        children: [
          {
            path: '/patients/enroll',
            element: (
              // <Suspense fallback={<CustomLoading />}>
              <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                <PatientEnrolmentLazy />
              </UserPermissionPage>
              // </Suspense>
            ),
          },
          {
            path: '/patients/:patientSourceId/approve',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                  <PatientEnrolmentLazy />
                </UserPermissionPage>
              </Suspense>
            ),
          },

          {
            path: '/patients/:patientSourceId/draft',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                  <PatientEnrolmentLazy />
                </UserPermissionPage>
              </Suspense>
            ),
          },
          {
            path: '/patients/:patientSourceId',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                  <PatientProfile />
                </UserPermissionPage>
              </Suspense>
            ),
          },
          {
            path: '/patients',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                  <PatientList />
                  <Outlet />
                </UserPermissionPage>
              </Suspense>
            ),
            children: [
              {
                path: 'active',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <ActivePatientList />
                  </Suspense>
                ),
              },
              {
                path: 'deactive',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                      <DeactivePatientList />
                    </UserPermissionPage>
                  </Suspense>
                ),
              },
              {
                path: 'draft',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
                      <DraftPatientList />
                    </UserPermissionPage>
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: '/appointments',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
              <Appointments />
            </UserPermissionPage>
          </Suspense>
        ),
      },
      {
        path: '/logs',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <UserPermissionPage roles={[APP_ROLES_DOCTORS, APP_ROLES_CARE_TEAM]}>
              <HistoryLogs />
            </UserPermissionPage>
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <Page404 />
          </Suspense>
        ),
      },
    ],
  },
];
