/** @format */

import React from 'react';
import { Typography, useTheme } from '@mui/material';

// Components.
import TrendLineChart from '../../common/charts/TrendLineChart';

// Types
import { BasicDetailsType } from '../../../types/Patients.types';

// CSS.
import './PatientTrendline.scss';

interface PatientTrendlineProps {
  id: string;
  data: any;
  patientBasicDetails: BasicDetailsType;
}

const PatientTrendline: React.FunctionComponent<PatientTrendlineProps> = (props) => {
  const appTheme = useTheme();

  const VITALS_MAPPING: any = {
    BLOOD_PRESSURE: 'BP',
    HEART_RATE: 'Pulse Rate',
    EGFR: 'eGFR',
    POTASSIUM: 'K',
    WEIGHT: 'Weight',
  };

  let name = '';

  if (props.patientBasicDetails && props.patientBasicDetails?.firstName && props.patientBasicDetails?.lastName) {
    name = props.patientBasicDetails?.firstName + ' ' + props.patientBasicDetails?.lastName;
  }

  if (!props.data || props.data?.length <= 0) {
    return (
      <div id='linechart-parent' style={{ minWidth: 'fit-content' }}>
        <Typography variant='fontReg14' fontFamily='Lato' color={appTheme.palette.customColor.text}>
          No Data to display
        </Typography>
      </div>
    );
  }

  return (
    <div id='linechart-parent'>
      <Typography className='line-chart-heading' variant='h6' component='h6' align='left'>
        {name.length !== 0 ? (
          <Typography variant='fontBold14' color={appTheme.palette.customColor.text}>
            {VITALS_MAPPING[props.id] || 'Vitals Reading'} - {name} / {props.patientBasicDetails?.patientId}
          </Typography>
        ) : (
          <Typography variant='fontBold14' color={appTheme.palette.customColor.text}>
            {VITALS_MAPPING[props.id] || 'Vitals Reading'} - {props.patientBasicDetails?.patientId}
          </Typography>
        )}
      </Typography>
      <div className='data-trendline-div'>
        <TrendLineChart
          id={'trendline-chart'}
          vitalName={props.id}
          data={props.data || []}
          dimensions={{ width: 350, height: 180 }}
          viewBox={{ minX: 0, minY: 0, width: 0, height: 0 }}
          margin={{ top: 0, right: 0, bottom: 0, left: 30 }}
          range={false}
          lowerBound={''}
          upperBound={''}
          continuousLine={true}
          onClickSetContinuousLine={console.log}
          color={'#D80914'}
          toggleDots={false}
          toggleTooltip={false}
          toggleVerticalPipe={false}
          toggleXAxisGrid={false}
          toggleYAxisGrid={false}
          tickSize={0}
          toggleMedianLine={true}
          yAxisTicks={5}
          xAxisTicks={0}
          xAxisTickSize={0}
          yAxisTickSize={0}
          timeFormat={'%b %d'}
          xAxisNice={0}
          yAxisNice={0}
          xAxisPosition={'BOTTOM'}
          toggleSVGIcon={false}
          toggleSVGIconTooltip={false}
          rangeXAxisOffset={40}
          rangeYAxisOffset={15}
          scale={'SCALE_POINT'}
        />
      </div>
    </div>
  );
};

export default PatientTrendline;
