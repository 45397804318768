/** @format */

import React from 'react';
import * as _ from 'underscore';
import { isEmpty } from 'lodash';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Switch, { SwitchProps } from '@mui/material/Switch';

import CommentIcon from '@mui/icons-material/Comment';
import VideoCallIcon from '@mui/icons-material/VideoCall';

import ChatModal from '../chats/ChatModal';
import PatientDeactivationReasonModal from './PatientDeactivationReasonModal';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { PatientProfileContext } from '../../pages/patient-profile/PatientProfileContext';
import { handleClickToggleVideoCall } from '../../reducers/user-region-reducer/UserRegionSlice';

import { PatientDeactivationReasonType } from '../../types/Patients.types';

import { ETHNICITY_OPTIONS, PATIENT_ACTIVE_STATUS, PATIENT_DEACTIVE_STATUS } from '../../constants/PatientEnrollment';
import { updateDeactivePatient } from '../../reducers/patient-list-reducer/ActivePatientListSlice';

const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface PatientBasicDetailsProps {}

const PatientBasicDetails: React.FC<PatientBasicDetailsProps> = () => {
  const { patientInfo, noteUsersOptions, handleSubmitPatientDeactivation, isPatientDeactivationUpdating, patientVideoCallStatus } = React.useContext(PatientProfileContext);
  const { basicDetails } = patientInfo;

  const { patientDetials } = useAppSelector((state) => state.userRegion);

  const reduxDispatch = useAppDispatch();

  const [contactDetailsAnchorEl, setContactDetailsAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [isOpenMessageChatModal, setIsOpenMessageChatModal] = React.useState<boolean>(false);

  const [isOpenPatientDeactivationModal, setIsOpenPatientDeactivationModal] = React.useState<boolean>(false);

  const toggleMessageChatModal = () => {
    setIsOpenMessageChatModal((prev) => !prev);
  };

  const handleClickContactDetails = (event: React.MouseEvent<HTMLButtonElement>) => {
    setContactDetailsAnchorEl(event.currentTarget);
  };

  const handleCloseContactDetails = () => {
    setContactDetailsAnchorEl(null);
  };

  const togglePatientDeactivationModal = () => {
    setIsOpenPatientDeactivationModal((prev) => !prev);
  };

  const handleClickPatientDeactivation = async (data: PatientDeactivationReasonType) => {
    await handleSubmitPatientDeactivation(data);
    togglePatientDeactivationModal();
    reduxDispatch(updateDeactivePatient(patientInfo));
  };

  const _renderChatModal = React.useMemo(() => {
    return <ChatModal userList={noteUsersOptions} patientBasicInfo={patientInfo?.basicDetails!} isOpen={isOpenMessageChatModal} handleClose={toggleMessageChatModal} />;
  }, [isOpenMessageChatModal, noteUsersOptions, patientInfo?.basicDetails]);

  return (
    <Grid item xs={12} container sx={{ p: '8px', background: '#F5F9FE', borderRadius: '8px' }} gap={2} alignItems='center'>
      {isOpenPatientDeactivationModal && (
        <PatientDeactivationReasonModal
          isOpen={isOpenPatientDeactivationModal}
          onClose={togglePatientDeactivationModal}
          onClickConfirm={handleClickPatientDeactivation}
          isLoading={isPatientDeactivationUpdating}
          disabled={isPatientDeactivationUpdating}
        />
      )}

      <Grid item xs='auto'>
        <Typography variant='fontBold20' color='#3E4685'>
          {basicDetails?.firstName || ''} {basicDetails?.lastName || ''} {basicDetails?.age || ''} {`(${basicDetails?.gender?.slice(0, 1)})` || ''}
        </Typography>
      </Grid>

      <Grid item pr={1} xs='auto'>
        <IOSSwitch
          onChange={togglePatientDeactivationModal}
          checked={basicDetails?.patientStatus === PATIENT_ACTIVE_STATUS || false}
          disabled={isPatientDeactivationUpdating || basicDetails?.patientStatus === PATIENT_DEACTIVE_STATUS}
        />
      </Grid>

      <Grid item xs='auto' sx={{ ml: 'auto' }}>
        <Typography variant='fontReg14' color='secondary' component='div'>
          Patient ID
        </Typography>
        <Typography mt={1} variant='fontSemiBold14' color='#33425B'>
          {basicDetails?.patientId}
        </Typography>
      </Grid>

      <Grid item xs='auto'>
        <Typography variant='fontReg14' color='secondary' component='div'>
          Ethnicity
        </Typography>
        <Typography mt={1} variant='fontSemiBold14' color='#33425B'>
          {ETHNICITY_OPTIONS.find((ele) => ele.value === basicDetails?.ethnicity)?.label}
        </Typography>
      </Grid>

      {!_.isEmpty(patientVideoCallStatus.callStartedTime) && _.isEmpty(patientVideoCallStatus.callEndedTime) && (
        <Chip
          label={
            <Typography component='div' variant='fontSemiBold14' sx={{ textTransform: 'capitalize' }}>
              on call
            </Typography>
          }
          size='medium'
          color={_.isEmpty(patientVideoCallStatus.callEndedTime) ? 'success' : 'error'}
          variant='outlined'
          sx={{ mx: 0.5 }}
        />
      )}

      <Grid item xs='auto' container gap={1} alignItems='center'>
        <IconButton onClick={toggleMessageChatModal} color='primary' sx={{ borderRadius: '5px', border: '1px solid #9BB4CC', background: '#FFF', padding: '4px', minWidth: '32px', minHeight: '32px' }}>
          <CommentIcon sx={{ fontSize: '20px' }} />
        </IconButton>

        <IconButton
          disabled={patientInfo?.basicDetails?.patientStatus !== PATIENT_ACTIVE_STATUS || !isEmpty(patientDetials)}
          onClick={() => reduxDispatch(handleClickToggleVideoCall(patientInfo.basicDetails!))}
          color='primary'
          sx={{ position: 'relative', borderRadius: '5px', border: '1px solid #9BB4CC', background: '#FFF', padding: '4px', minWidth: '32px', minHeight: '32px' }}>
          <VideoCallIcon />
        </IconButton>

        <Button onClick={handleClickContactDetails} variant='outlined' color='primary' sx={{ borderRadius: '5px', background: '#FFF', padding: '4px', minWidth: '32px', minHeight: '32px' }}>
          Other details
        </Button>

        <Popover
          id='simple-popover'
          open={Boolean(contactDetailsAnchorEl)}
          anchorEl={contactDetailsAnchorEl}
          onClose={handleCloseContactDetails}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              marginTop: '3px',
              width: '30rem',
              padding: '10px',
              pb: '20px',
            },
          }}>
          <Grid item xs={12} container gap={3}>
            <Grid item xs={5.5}>
              <Typography variant='fontReg14' color='secondary' component='div'>
                Contact No
              </Typography>
              <Typography mt={1} variant='fontSemiBold14' color='#33425B'>
                {basicDetails?.phoneNumber}
              </Typography>
            </Grid>

            <Grid item xs={5.5}>
              <Typography variant='fontReg14' color='secondary' component='div'>
                Email
              </Typography>
              <Typography mt={1} variant='fontSemiBold14' color='#33425B'>
                {basicDetails?.email}
              </Typography>
            </Grid>

            <Grid item xs={5.5}>
              <Typography variant='fontReg14' color='secondary' component='div'>
                Alt Contact No
              </Typography>
              <Typography mt={1} variant='fontSemiBold14' color='#33425B'>
                {basicDetails?.phoneNumberAlt || 'NA'}
              </Typography>
            </Grid>

            <Grid item xs={5.5}>
              <Typography variant='fontReg14' color='secondary' component='div'>
                Assigned Physician
              </Typography>
              <Typography mt={1} variant='fontSemiBold14' color='#33425B'>
                {basicDetails?.physicianName}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='fontReg14' color='secondary' component='div'>
                Address
              </Typography>
              <Typography mt={1} variant='fontSemiBold14' color='#33425B'>
                {basicDetails?.address}
              </Typography>
            </Grid>
          </Grid>
        </Popover>
      </Grid>

      {isOpenMessageChatModal && _renderChatModal}
    </Grid>
  );
};

export default PatientBasicDetails;
