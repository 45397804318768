/** @format */

import * as d3 from 'd3';
import _ from 'underscore';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import { useTheme, Theme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material';

// import icons
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// Components.
import ShowGraphsMenu from '../patient-profile/patient-activity/ShowGraphsMenu';
import RCACharts from '../../components/common/charts/RCACharts';
import { dateFormater } from '../../components/common/charts/rca-resources/utils';
import CustomLoading from '../../components/common/custom-loading/CustomLoading';
import DateRangePicker from '../../components/common/date-range-picker/DateRangePicker';
import { ComponentErrorBoundary } from '../../components/common/error/ComponentErrorBoundary';

import { capitalizeFirstLetter } from '../../utils/functions/case-change';
import { getAlertsForRca, getFormattedTextForRcaAlerts } from '../../utils/alert-utils';
import { deCouplePatientVitalsIntoFormValue, formatPatientLabReportsForLineChart, formatPatientMedicalEventsForLineChart, getAllUserSelectedDateAndValues } from '../../utils/patient-vitals-utils';

import { useAppDispatch } from '../../app/hooks';
import { setToast } from '../../reducers/toast-reducer/ToastReducer';

import { useMutation, useQuery } from '@tanstack/react-query';
import { MAPPING_ALGO_VITALS } from '../../constants/alert';
import { VITALS_SOURCE_TYPE_APPLE_WATCH, VITALS_SOURCE_TYPE_EXCEPT_APPLE_WATCH } from '../../constants/PatientVitals';

import { PatientInfoType } from '../../types/Patients.types';
import { PatientTimeLineType } from '../../types/Timeline.types';
import { PatientTasksItemType } from '../../types/PatientTasks.type';
import { PatientVitalsGraphType } from '../../types/PatientListPage.types';

// Css
import './../patient-profile/patient-activity/AlertsAndPatientActivity.scss';

import { fetchPatientVitalsByRangeGraph } from '../../service/patient-vitals';
import { fetchPatientMedicalEvents } from '../../service/patient-medical-events';
import { fetchPatientAlertNotification } from '../../service/patientAlertNotification';
import { createPatientVitalsGraphPreference, getPatientVitalsGraphPreference, updatePatientVitalsGraphPreference } from '../../service/patient-preference';

export type VitalHistoryFiltersType = {
  startDate: string;
  endDate: string;
  dateRangeView: 'WEEK' | 'MONTH';
};

interface PatientAlertsAndVitalsHistoryProps {
  showInteractionPanel?: boolean;

  patientInfo: PatientInfoType;
}

const PatientAlertsAndVitalsHistory: FC<PatientAlertsAndVitalsHistoryProps> = (props) => {
  const { showInteractionPanel = true, patientInfo } = props;

  const reduxDispatch = useAppDispatch();

  const patientSourceId = props.patientInfo.basicDetails?.patientSourceId as string;

  const [patientGraphPreference, setPatientGraphPreference] = React.useState<{
    // Toggle Graphs
    dbp: boolean;
    sbp: boolean;
    spo2: boolean;
    steps: boolean;
    weight: boolean;
    hrApple: boolean;
    hrOmron: boolean;
    medicalEvents: boolean;

    // Other Settings
    medianLine: boolean;
  }>({
    // Toggle Graphs
    dbp: true,
    sbp: true,
    spo2: true,
    steps: true,
    weight: true,
    hrApple: true,
    hrOmron: true,
    medicalEvents: true,

    // Other Settings
    medianLine: true,
  });

  const [isPatientVitalsHistoryLoading, setPatientVitalsHistoryLoading] = React.useState(true);
  const [vitalHistoryFilters, setVitalHistoryFilters] = React.useState<VitalHistoryFiltersType>({
    startDate: DateTime.local().minus({ days: 13 }).toISO()!,
    endDate: DateTime.local().toISO()!,
    dateRangeView: 'WEEK',
  });

  const [patientVitalsHistory, setPatientVitalsHistory] = React.useState<PatientVitalsGraphType[]>([]);
  const [patientVitalsHistoryAppleWatch, setPatientVitalsHistoryAppleWatch] = React.useState<PatientVitalsGraphType[]>([]);
  const [patientVitalsHistoryMedicalEvent, setPatientVitalsHistoryMedicalEvent] = React.useState<PatientTimeLineType[]>([]);
  const [patientMedicationTask, setPatientMedicationTask] = React.useState<PatientTasksItemType<any, any>[]>([]);

  const handleChangeVitalHistoryFitlers = (filters: Partial<VitalHistoryFiltersType>) => {
    setVitalHistoryFilters((prev) => ({ ...prev, ...filters }));
  };

  const fetchPatientVitalsByRangeGraphQuery = useQuery({
    queryKey: ['fetchPatientVitalsByRangeGraph', patientSourceId, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate],
    queryFn: async () => fetchPatientVitalsByRangeGraph(patientSourceId, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate, VITALS_SOURCE_TYPE_EXCEPT_APPLE_WATCH),
  });

  const fetchPatientVitalsAppleWatchByRangeGraphQuery = useQuery({
    queryKey: ['fetchPatientVitalsAppleWatchByRangeGraphQuery', patientSourceId, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate],
    queryFn: async () => fetchPatientVitalsByRangeGraph(patientSourceId, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate, VITALS_SOURCE_TYPE_APPLE_WATCH),
  });

  const fetchPatientVitalsMedicalEventsQuery = useQuery({
    queryKey: ['fetchPatientVitalsMedicalEventsQuery', patientSourceId, patientInfo?.basicDetails?.enrollmentDate],
    queryFn: async () => {
      const report = await fetchPatientMedicalEvents(patientSourceId, new Date(patientInfo?.basicDetails?.enrollmentDate as string).toISOString(), new Date().toISOString());
      return report;
    },
  });

  const fetchPatientMedicationTasksQuery = useQuery({
    queryKey: ['fetchPatientMedicationTasks', patientSourceId],
    queryFn: async () => fetchPatientAlertNotification(patientSourceId, 'MEDICATION_TASKS', '50', '0', 'MAX'),
  });

  const fetchPatientGraphPreference = useQuery({
    queryKey: ['getPatientGraphPreference', patientSourceId],
    queryFn: async () => getPatientVitalsGraphPreference(patientSourceId),
  });

  const updatePatientGraphPreference = useMutation({
    mutationFn: ({ patientGraphPreference }: { patientGraphPreference: any }): Promise<any> => {
      if (_.isEmpty(fetchPatientGraphPreference.data)) {
        return createPatientVitalsGraphPreference(patientSourceId, patientGraphPreference);
      }

      return updatePatientVitalsGraphPreference(patientSourceId, patientGraphPreference);
    },
    onSuccess: () => {
      fetchPatientGraphPreference.refetch();
      reduxDispatch(setToast({ message: 'Successfully updated graph preference.', code: 'success' }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to update graph preference.', code: 'error' }));
    },
  });

  const handleSubmitPatientGraphPreference = (patientGraphPreference: any) => {
    updatePatientGraphPreference.mutateAsync({ patientGraphPreference });
  };

  React.useEffect(() => {
    setPatientVitalsHistoryLoading(fetchPatientVitalsByRangeGraphQuery.isPending);
    setPatientVitalsHistory(fetchPatientVitalsByRangeGraphQuery.data || []);

    setPatientVitalsHistoryAppleWatch(fetchPatientVitalsAppleWatchByRangeGraphQuery.data || []);

    setPatientVitalsHistoryMedicalEvent(fetchPatientVitalsMedicalEventsQuery.data || []);

    setPatientMedicationTask(fetchPatientMedicationTasksQuery.data || []);

    if (!_.isEmpty(fetchPatientGraphPreference?.data?.preferences)) {
      setPatientGraphPreference(fetchPatientGraphPreference?.data?.preferences);
    }
  }, [
    patientSourceId,
    fetchPatientMedicationTasksQuery.data,
    fetchPatientVitalsAppleWatchByRangeGraphQuery.data,
    fetchPatientVitalsByRangeGraphQuery.data,
    // fetchPatientVitalsByRangeGraphQuery.isPending,
    fetchPatientVitalsMedicalEventsQuery.data,

    fetchPatientGraphPreference.data,
  ]);

  const { recentAlerts, previousAlerts } = getAlertsForRca(patientMedicationTask);

  const [svgElementDynDimensions, setsvgElementDynDimensions] = React.useState<{ width: number; height: number }>({ width: 1000, height: 800 });

  const rcaChartRef = React.useRef<HTMLDivElement | null>(null);

  const handleChangeToggleGraphs = (payload: { sbp: boolean; dbp: boolean; hrApple: boolean; hrOmron: boolean; weight: boolean; spo2: boolean; steps: boolean; medicalEvents: boolean }) => {
    handleSubmitPatientGraphPreference({ ...patientGraphPreference, ...payload });
  };

  // const handleChangeWeekly = () => {
  //   const endDate = DateTime.now().minus({ days: 0 }).toFormat('yyyy-MM-dd');
  //   const startDate = DateTime.now().minus({ days: 14 }).toFormat('yyyy-MM-dd');
  //   handleChangeVitalHistoryFitlers({ startDate, endDate, dateRangeView: 'WEEK' });
  // };

  // const handleChangeMonthly = () => {
  //   const endDate = DateTime.now().minus({ days: 0 }).toFormat('yyyy-MM-dd');
  //   const startDate = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd');
  //   handleChangeVitalHistoryFitlers({ startDate, endDate, dateRangeView: 'MONTH' });
  // };

  const handleClickRcaWeekAndMonthPrevious = () => {
    let startDate = DateTime.fromJSDate(new Date(vitalHistoryFilters.endDate)).toUTC().minus({ days: 27 }).toFormat('yyyy-MM-dd');
    let endDate = DateTime.fromJSDate(new Date(vitalHistoryFilters.endDate)).toUTC().minus({ days: 14 }).toFormat('yyyy-MM-dd');

    if (vitalHistoryFilters.dateRangeView === 'MONTH') {
      startDate = DateTime.fromJSDate(new Date(vitalHistoryFilters.endDate)).toUTC().minus({ months: 2 }).toFormat('yyyy-MM-dd');
      endDate = DateTime.fromJSDate(new Date(vitalHistoryFilters.endDate)).toUTC().minus({ months: 1 }).toFormat('yyyy-MM-dd');
    }
    handleChangeVitalHistoryFitlers({ startDate, endDate, dateRangeView: vitalHistoryFilters.dateRangeView });
  };

  const handleClickRcaWeekAndMonthNext = () => {
    let startDate = DateTime.fromJSDate(new Date(vitalHistoryFilters.endDate)).toUTC().plus({ days: 1 }).toFormat('yyyy-MM-dd');
    let endDate = DateTime.fromJSDate(new Date(vitalHistoryFilters.endDate)).toUTC().plus({ days: 14 }).toFormat('yyyy-MM-dd');

    if (vitalHistoryFilters.dateRangeView === 'MONTH') {
      endDate = DateTime.fromJSDate(new Date(vitalHistoryFilters.endDate)).toUTC().plus({ months: 1 }).toFormat('yyyy-MM-dd');
      startDate = DateTime.fromJSDate(new Date(vitalHistoryFilters.endDate)).toUTC().plus({ months: 0 }).toFormat('yyyy-MM-dd');
    }
    handleChangeVitalHistoryFitlers({ startDate, endDate, dateRangeView: vitalHistoryFilters.dateRangeView });
  };

  const handleClickAlertShiftRcaToWeek = (alert: PatientTasksItemType<any, any>) => {
    const endDate = DateTime.fromJSDate(new Date(alert.timestamp)).plus({ weeks: 1 }).toFormat('yyyy-MM-dd');
    const startDate = DateTime.fromJSDate(new Date(alert.timestamp)).minus({ weeks: 1 }).toFormat('yyyy-MM-dd');

    handleChangeVitalHistoryFitlers({ startDate, endDate, dateRangeView: vitalHistoryFilters.dateRangeView });
  };

  const handleScroll = (event: any) => {
    if (event.target.scrollTop > 0) {
      const element: any = d3?.select('#corexaxis')?.nodes()[0];
      element.setAttribute('transform', 'translate(250, ' + (Number(event.target.scrollTop) + 30) + ')');

      // const medicalEventsElement: any = d3?.select('#MEDICAL-EVENTScore')?.nodes()[0];
      // medicalEventsElement.setAttribute('transform', 'translate(300, ' + (Number(event.target.scrollTop) + 30) + ')');
    } else {
      const element: any = d3?.select('#corexaxis')?.nodes()[0];
      element.setAttribute('transform', 'translate(250, 30)');

      // const medicalEventsElement: any = d3?.select('#MEDICAL-EVENTScore')?.nodes()[0];
      // medicalEventsElement.setAttribute('transform', 'translate(300, 36)');
    }
  };

  const getChartDimensions = () => {
    if (rcaChartRef.current) {
      const width = rcaChartRef.current?.getBoundingClientRect().width;

      const height = rcaChartRef.current?.getBoundingClientRect().height;
      if (width > 0 && height > 0) {
        setsvgElementDynDimensions((prev: { width: number; height: number }) => {
          return { ...prev, width, height };
        });
      }
    }
  };

  const handleToggleMedianLine = (payload: boolean) => {
    handleSubmitPatientGraphPreference({ ...patientGraphPreference, medianLine: payload });
  };

  const _renderVitalsHistoryFitler = () => {
    return (
      <>
        <Grid item xs='auto' container>
          {/* <MenuItem disableRipple disableTouchRipple sx={{ '&:hover': { backgroundColor: 'inherit', cursor: 'default' }, mr: '5px' }}>
            <SettingsIcon sx={{ mr: '18px' }} />

            <ButtonGroup disableFocusRipple disableRipple variant='contained' aria-label='loading button group' sx={{ gap: '5px', '& button': { border: '0px !important' } }}>
              <Button color={toggleMedianLine ? 'primary' : 'info'} onClick={handleToggleMedianLine}>
                Median
              </Button>
            </ButtonGroup>
          </MenuItem> */}

          {/* <ButtonGroup disableFocusRipple disableRipple variant='contained' aria-label='loading button group'>
            <Button color={vitalHistoryFilters?.dateRangeView === 'WEEK' ? 'primary' : 'info'} onClick={handleChangeWeekly}>
              Bi-Weekly
            </Button>
            <Button color={vitalHistoryFilters?.dateRangeView === 'MONTH' ? 'primary' : 'info'} onClick={handleChangeMonthly}>
              Monthly
            </Button>
          </ButtonGroup> */}
        </Grid>

        <Grid item xs='auto' container>
          <Grid item xs='auto'>
            <Button aria-label='left' variant='outlined' className='rca-week-next-previous-button' onClick={handleClickRcaWeekAndMonthPrevious}>
              <ArrowLeftIcon fontSize='large' />
            </Button>
          </Grid>
          <Grid item xs='auto' mx={2}>
            <DateRangePicker
              showIcon={false}
              startDate={vitalHistoryFilters.startDate}
              endDate={vitalHistoryFilters.endDate}
              handleChangeStartDate={(startDate) => handleChangeVitalHistoryFitlers({ startDate })}
              handleChangeEndDate={(endDate) => handleChangeVitalHistoryFitlers({ endDate })}
              maxDate={new Date()}
              selectDaysRange={14}
            />
          </Grid>
          <Grid item xs='auto'>
            <Button aria-label='right' variant='outlined' className='rca-week-next-previous-button' onClick={handleClickRcaWeekAndMonthNext}>
              <ArrowRightIcon fontSize='large' />
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <ShowGraphsMenu
            initalState={patientGraphPreference}
            toggleGraphs={{
              sbp: patientGraphPreference?.sbp,
              dbp: patientGraphPreference?.dbp,
              hrApple: patientGraphPreference?.hrApple,
              hrOmron: patientGraphPreference?.hrOmron,
              weight: patientGraphPreference?.weight,
              spo2: patientGraphPreference?.spo2,
              steps: patientGraphPreference?.steps,
              medicalEvents: patientGraphPreference?.medicalEvents,
            }}
            handleChangeToggleGraphs={handleChangeToggleGraphs}
            toggleMedianLine={patientGraphPreference?.medianLine}
            handleToggleMedianLine={handleToggleMedianLine}
          />
        </Grid>
      </>
    );
  };

  React.useEffect(() => {
    getChartDimensions();
    window.addEventListener('resize', getChartDimensions);
    return () => window.removeEventListener('resize', getChartDimensions);
  }, [rcaChartRef.current, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate]);

  const [patientLabVitals, setPatientLabVitals] = React.useState<any[]>([]);

  React.useEffect(() => {
    const patientLabVitals = formatPatientLabReportsForLineChart(patientVitalsHistoryMedicalEvent);
    const labReportsValues = getAllUserSelectedDateAndValues(vitalHistoryFilters.startDate, vitalHistoryFilters.endDate, patientLabVitals, 'labReports');

    /* Adding Enrollment VitalReferences to Medical Event Chart */
    const finalLabReportsValues = [...labReportsValues];
    if (patientInfo?.medicalHistory?.vitalsReference?.length) {
      const vitalsReference = {
        date: DateTime.fromJSDate(new Date(patientInfo?.medicalHistory?.vitalsReference[0]?.timestamp!)).startOf('day').toJSDate(),
        labReports: patientInfo?.medicalHistory?.vitalsReference
          ?.map((ele) => {
            // As Feet & Inches are coming seperately, we are combining it to 1 key HEIGHT
            const feetOrInches = ele?.measurements?.find((e) => e.name?.toLowerCase() === 'feet');
            if (feetOrInches) {
              return [
                {
                  name: 'HEIGHT',
                  value: `${ele.measurements[0]?.value} ft ${ele.measurements[1]?.value} in`,
                  unit: '',
                },
              ];
            }

            return ele.measurements.flat();
          })
          .flat(),
      };
      finalLabReportsValues.push(vitalsReference);
    }
    /* Adding Enrollment VitalReferences to Medical Event Chart */

    setPatientLabVitals(finalLabReportsValues);
  }, [patientVitalsHistoryMedicalEvent, vitalHistoryFilters.startDate, vitalHistoryFilters.endDate, patientInfo?.medicalHistory?.vitalsReference]);

  const [medicalEvents, setMedicalEvents] = React.useState<any[]>([]);

  React.useEffect(() => {
    const patientMedicalEvents = formatPatientMedicalEventsForLineChart(patientVitalsHistoryMedicalEvent);
    const medicalEventsValues = getAllUserSelectedDateAndValues(vitalHistoryFilters.startDate, vitalHistoryFilters.endDate, patientMedicalEvents, 'medical_events');
    setMedicalEvents(medicalEventsValues);
  }, [patientVitalsHistoryMedicalEvent, vitalHistoryFilters.endDate, vitalHistoryFilters.startDate]);

  // const patientMedicalEvents = formatPatientMedicalEventsForLineChart(patientVitalsHistoryMedicalEvent);
  // const medicalEventsValues = getAllUserSelectedDateAndValues(vitalHistoryFilters.startDate, vitalHistoryFilters.endDate, patientMedicalEvents, 'medical_events');

  const [kccqHistory, setKccqHistory] = React.useState<any[]>([]);
  const [walkTestTimeHistory, setWalkTestTimeHistory] = React.useState<any[]>([]);

  React.useEffect(() => {
    const kccqApiData = patientInfo?.medicalHistory?.kcqqcHistory;
    const kccqGroupedByDate = _.groupBy(kccqApiData || [], (ele) => ele.date);

    const kccqHistoryData = Object.entries(kccqGroupedByDate).map(([date, value]) => {
      const dateObject = dateFormater(date);
      return {
        date: dateObject,
        kccqHistory: value.map((ele) => {
          return {
            ...ele,
            date: dateObject,
          };
        }),
      };
    });
    const currentKcqqcObj = {
      date: dateFormater(patientInfo?.medicalHistory?.kcqqc?.date!),
      kccqHistory: [{ ...patientInfo?.medicalHistory?.kcqqc, date: dateFormater(patientInfo?.medicalHistory?.kcqqc?.date!) }],
    };
    setKccqHistory([currentKcqqcObj, ...kccqHistoryData]);

    const walkTestTimeApiData = patientInfo?.medicalHistory?.walkTestTimeHistory;
    const walkTestTimeGroupedByDate = _.groupBy(walkTestTimeApiData || [], (ele) => ele.date);
    const walkTestTimeHistoryData = Object.entries(walkTestTimeGroupedByDate).map(([date, value]) => {
      const dateObject = dateFormater(date);
      return {
        date: dateObject,
        walkTestHistory: value.map((ele) => {
          return {
            ...ele,
            date: dateObject,
          };
        }),
      };
    });
    const currentWalkTestTimeObj = {
      date: dateFormater(patientInfo?.medicalHistory?.walkTestTime?.date!),
      walkTestHistory: [{ ...patientInfo?.medicalHistory?.walkTestTime, date: dateFormater(patientInfo?.medicalHistory?.walkTestTime?.date!) }],
    };
    setWalkTestTimeHistory([currentWalkTestTimeObj, ...walkTestTimeHistoryData]);
  }, [patientInfo]);

  const initialVitals = React.useMemo(() => {
    const hrInitial = deCouplePatientVitalsIntoFormValue('HEART_RATE', patientInfo.medicalHistory?.vitalsReference);
    const sbpInitial = deCouplePatientVitalsIntoFormValue('BLOOD_PRESSURE', patientInfo.medicalHistory?.vitalsReference, 'SYSTOLIC');
    const dbpInitial = deCouplePatientVitalsIntoFormValue('BLOOD_PRESSURE', patientInfo.medicalHistory?.vitalsReference, 'DIASTOLIC');
    const weightInitial = deCouplePatientVitalsIntoFormValue('WEIGHT', patientInfo.medicalHistory?.vitalsReference);
    const spo2Initial = deCouplePatientVitalsIntoFormValue('SPO2', patientInfo.medicalHistory?.vitalsReference);
    const stepCountInitial = deCouplePatientVitalsIntoFormValue('STEP_COUNT', patientInfo.medicalHistory?.vitalsReference);

    return {
      hrInitial,
      sbpInitial,
      dbpInitial,
      weightInitial,
      spo2Initial,
      stepCountInitial,
    };
  }, [patientInfo]);

  const currentVitals = React.useMemo(() => {
    const patientLatestVitals = patientInfo?.latestVitals?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()) || [];

    const hrNormal = patientLatestVitals.filter((item: any) => !item.sourceType?.toLowerCase().includes('apple'));
    const hrApple = patientLatestVitals.filter((item: any) => item.sourceType?.toLowerCase().includes('apple'));
    const hrCurrent = deCouplePatientVitalsIntoFormValue('HEART_RATE', hrNormal);
    const hrAppleCurrent = deCouplePatientVitalsIntoFormValue('HEART_RATE', hrApple);
    const sbpCurrent = deCouplePatientVitalsIntoFormValue('BLOOD_PRESSURE', patientLatestVitals, 'SYSTOLIC');
    const dbpCurrent = deCouplePatientVitalsIntoFormValue('BLOOD_PRESSURE', patientLatestVitals, 'DIASTOLIC');
    const weightCurrent = deCouplePatientVitalsIntoFormValue('WEIGHT', patientLatestVitals);
    const spo2Current = deCouplePatientVitalsIntoFormValue('SPO2', patientLatestVitals);
    const stepCountCurrent = deCouplePatientVitalsIntoFormValue('STEP_COUNT', patientLatestVitals);

    return {
      hrCurrent,
      hrAppleCurrent,
      sbpCurrent,
      dbpCurrent,
      weightCurrent,
      spo2Current,
      stepCountCurrent,
    };
  }, [patientInfo?.latestVitals]);

  return (
    <Box id='patient-activity-container'>
      {showInteractionPanel && (
        <Grid item xs={12} container alignItems='center'>
          <Grid item xs={12} lg='auto' container sx={{ ml: 'auto' }} gap={2} pb='10px' pt='10px'>
            {_renderVitalsHistoryFitler()}
          </Grid>
        </Grid>
      )}

      {isPatientVitalsHistoryLoading && (
        <Box sx={{ position: 'relative', top: '15em' }}>
          <CustomLoading />
        </Box>
      )}

      {/* <Button onClick={handleToggleRcaDrawer}>TOGGLE</Button> */}

      <Grid item xs container sx={{ overflow: 'hidden', height: '100%' }}>
        <Grid item xs={2.2} textAlign='center' sx={{ width: '256px', padding: '0px 5px 0px 0px' }}>
          {/* <Typography variant='fontReg18' color={appTheme.palette.customColor.primary}>
            No Alerts
          </Typography> */}

          <Accordion sx={{ border: '1px solid #9BB4CC', boxShadow: 'none', marginBottom: '5px', '& > .Mui-expanded': { minHeight: '48px !important' } }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel-content' id='panel-header' sx={{ '& > div': { margin: '0px !important' } }}>
              <Typography variant='fontSemiBold14' color='#33425B'>
                Recent Alerts ({recentAlerts.length})
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails sx={{ px: 0, cursor: 'pointer', padding: '5px', '& > div': { marginBottom: '5px' } }}>
              {recentAlerts.map((ele, index) => {
                const alertVital = MAPPING_ALGO_VITALS[ele.payload.inputPayload.causes[0] as keyof typeof MAPPING_ALGO_VITALS];
                const formattedDate = DateTime.fromJSDate(new Date(ele.timestamp)).toFormat('hh:mm a, MMM d');

                return (
                  <Grid key={index} onClick={() => handleClickAlertShiftRcaToWeek(ele)} container alignItems='center' sx={{ '&:hover': { backgroundColor: '#EFF1FF' } }} p={0.5}>
                    <Grid item xs={2} alignSelf='flex-end' textAlign='left'>
                      <WarningAmberIcon sx={{ color: ele.type.match('CRITICAL_ALERTS') ? 'red' : 'orange', fontSize: '25px' }} />
                    </Grid>
                    <Grid item xs textAlign='start'>
                      <Typography component='div' variant='fontSemiBold14' color='#33425B'>
                        {/* 64 BPM */}
                        {getFormattedTextForRcaAlerts(alertVital)}
                      </Typography>
                      <Typography component='div' variant='fontReg14' color='#33425B'>
                        {' '}
                        {/* Heart rate */}
                        {capitalizeFirstLetter(ele?.payload?.inputPayload?.reason)}
                      </Typography>
                      <Typography component='div' variant='fontReg14'>
                        {/* 9:00 PM, May 22 */}
                        {formattedDate}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ border: '1px solid #9BB4CC', boxShadow: 'none', marginBottom: '5px', '& > .Mui-expanded': { minHeight: '48px !important' } }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel-content' id='panel-header' sx={{ '& > div': { margin: '0px !important' } }}>
              <Typography variant='fontSemiBold14' color='#33425B'>
                Previous Alerts ({previousAlerts.length})
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails sx={{ px: 0, cursor: 'pointer', padding: '5px', '& > div': { marginBottom: '5px' } }}>
              {previousAlerts.map((ele, index) => {
                const alertVital = MAPPING_ALGO_VITALS[ele.payload.inputPayload.causes[0] as keyof typeof MAPPING_ALGO_VITALS];
                const formattedDate = DateTime.fromJSDate(new Date(ele.timestamp)).toFormat('hh:mm a, MMM d');

                return (
                  <Grid key={index} onClick={() => handleClickAlertShiftRcaToWeek(ele)} container alignItems='center' sx={{ '&:hover': { backgroundColor: '#EFF1FF' } }} p={0.5}>
                    <Grid item xs={2} alignSelf='flex-end' textAlign='left'>
                      <WarningAmberIcon sx={{ color: ele.type.match('CRITICAL_ALERTS') ? 'red' : 'orange', fontSize: '25px' }} />
                    </Grid>
                    <Grid item xs textAlign='start'>
                      <Typography component='div' variant='fontSemiBold14' color='#33425B'>
                        {/* 64 BPM */}
                        {getFormattedTextForRcaAlerts(alertVital)}
                      </Typography>
                      <Typography component='div' variant='fontReg14' color='#33425B'>
                        {' '}
                        {/* Heart rate */}
                        {capitalizeFirstLetter(ele?.payload?.inputPayload?.reason)}
                      </Typography>
                      <Typography component='div' variant='fontReg14'>
                        {/* 9:00 PM, May 22 */}
                        {formattedDate}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid xs className='vitals-history-body' onScroll={handleScroll} style={{ width: '100%' }}>
          <div className='vitals-history-inner-body'>
            <div /* ref={rcaChartRef} */ className='vitals-history-body-vital-chart'>
              <ErrorBoundary FallbackComponent={ComponentErrorBoundary}>
                <RCACharts
                  id={'rca-chart'}
                  rcaChartRef={rcaChartRef}
                  vitalsReferenceValues={initialVitals}
                  vitalsCurrentValues={currentVitals}
                  dimensions={{ width: Math.abs(svgElementDynDimensions?.width - 40), height: 100 }}
                  margin={{ top: 0, right: 0, bottom: 0, left: 250 }}
                  toggleGraphs={{
                    sbp: patientGraphPreference?.sbp,
                    dbp: patientGraphPreference?.dbp,
                    hrApple: patientGraphPreference?.hrApple,
                    hrOmron: patientGraphPreference?.hrOmron,
                    weight: patientGraphPreference?.weight,
                    spo2: patientGraphPreference?.spo2,
                    steps: patientGraphPreference?.steps,
                    medicalEvents: patientGraphPreference?.medicalEvents,
                  }}
                  data={{
                    patientVitals: patientVitalsHistory,
                    patientAppleWatchVitals: patientVitalsHistoryAppleWatch,
                    medicalEventsValues: medicalEvents,
                    labReportsValues: patientLabVitals,
                    kccqHistory,
                    walkTestTimeHistory,
                  }}
                  yAxisTicks={5}
                  timeFormat={'%d'}
                  xAxisPosition={'BOTTOM'}
                  rangeXAxisOffset={10}
                  rangeYAxisOffset={15}
                  rcaStartDate={vitalHistoryFilters.startDate}
                  rcaEndDate={vitalHistoryFilters.endDate}
                  toggleMedianLine={patientGraphPreference?.medianLine}
                />
              </ErrorBoundary>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientAlertsAndVitalsHistory;
