/** @format */

import AxiosInstance from './index';
import CONFIG from '../config/config';

export const getAllPatientsAPI = async (cardiologistId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: '/patients',
    params: {
      cardiologistId,
    },
  });

  return res.data || {};
};

export const getPatientApi = async (id: string) => {
  const res = await AxiosInstance({
    method: 'get',
    baseURL: CONFIG.patientApiUrl,
    url: `patients/${id}/basic`,
  });

  return res.data || {};
};

export const getPatientDetailsApi = async (id: string) => {
  const res = await AxiosInstance({
    method: 'get',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${id}/details`,
  });

  return res.data || {};
};

export const createPatientMedicalHistory = async ({ vitals, heartFailureMedicalHistory, sourceId }: any) => {
  const res = await AxiosInstance({
    method: 'put',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${sourceId}/medical/history`,
    data: {
      vitals,
      heartFailureMedicalHistory,
    },
  });

  return res.data || {};
};

export const getPatientMedicalHistory = async (sourceId: string) => {
  const res = await AxiosInstance({
    method: 'get',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${sourceId}/medical/history`,
  });

  return res.data || {};
};

export const searchPatient = async (searchParam: string, cancelToken: any, type?: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: CONFIG.patientApiUrl,
    url: '/patients/search',
    cancelToken: cancelToken.token,
    params: {
      searchKey: searchParam,
      patientStatus: type,
    },
  });
  return res.data || {};
};

export const getPatientPrescriptionsPdfReport = async (patinetSourceId: string, payload: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patinetSourceId}/prescriptions/pdf/templates/report`,
    responseType: 'blob',
    data: payload,
  });
  return res.data || {};
};

export const getPatientVideoCallCreds = async (patinetSourceId: string) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patinetSourceId}/video-call`,
  });
  return res.data || {};
};

export const pushNotificationInvite = async (notificationContent: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: CONFIG.patientApiUrl,
    url: '/patients/invite/notification',
    data: notificationContent,
  });
  return res.data || {};
};

export const updateUserLeavingVideoCall = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/video-call/leave`,
  });
  return res.data || {};
};

export const updatePatientVideoCallEnd = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: CONFIG.patientApiUrl,
    url: `/patients/${patientSourceId}/video-call/end`,
  });
  return res.data || {};
};
