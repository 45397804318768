/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';
import { RJSFSchema } from '@rjsf/utils';

import {
  GridInputField,
  GridTextareaField,
  TitleFieldTemplate,
  FieldErrorTemplate,
  GridSelectInputField,
  PhoneNumberInputField,
  FlexVitalsObjectFieldTemplate,
  GridTemplateObjectFieldTemplate,
} from '../components/common/react-json-schema';
import { Typography } from '@mui/material';
import { FlexInputField } from '../components/common/react-json-schema/GridInputField';
import { GridCheckBoxField } from '../components/common/react-json-schema/GridCheckBoxField';
import { FlexVitalsMultiInput } from '../components/common/react-json-schema/FlexVitalsObjectFieldTemplate';
import { GridColumnObjectTemplate } from '../components/common/react-json-schema/GridColumnObjectTemplate';
import { GridTemplateObjectWithoutCard } from '../components/common/react-json-schema/GridTemplateObjectFieldTemplate';

// Constants.
import {
  SYMPTOMS,
  SHOW_SYMPTOMS_DATA,
  NYHA_OPTIONS,
  IGNORE_SYMPTOMS,
  GENDER_OPTIONS,
  HF_TYPES_OPTIONS,
  ETHNICITY_OPTIONS,
  VITALS_FORM_FIELDS,
  CARDIOVASULCAR_DISEASES,
  NON_CARDIOVASCULAR_DISEASES,
  RELATIONSHIP_OF_ALT_CONTACT_OPTIONS,
  PATIENT_BASIC_FORM_FIELDS,
  IGNORE_ETHNICITY_OPTIONS,
} from '../constants/PatientEnrollment';

// SVGS.
import { BmiEquation } from '../assets/BmiEquation';
import { BmiEquationWithKgs } from '../assets/BmiEquationWithKgs';

// Types.
import { EditDetailsType } from '../types/EditDetails.types';
import { EnrollmentConfigType } from '../types/Patients.types';
import { FormDataInterface } from '../pages/patient-enrolment/PatientEnrolmentContext';
import { OrganizationMedicationType } from '../types/SystemMedications.types';
import CustomTemplatePrevMedications from '../components/common/react-json-schema/CustomTemplatePrevMedications';
import { convertToPascalCase } from '../utils/functions/case-change';

export interface UsePatientFormFieldsProps {
  editDetails: EditDetailsType;

  handleClickEditDetails: (variable: string) => void;

  tabPhysicianRef: React.MutableRefObject<HTMLDivElement | null>;
  tabPatientBasicDetailsRef: React.MutableRefObject<HTMLDivElement | null>;
  tabPatientMedicalHistoryRef: React.MutableRefObject<HTMLDivElement | null>;

  allDoctors: any[];

  enrolmentConfig: EnrollmentConfigType;

  formData: FormDataInterface;
  onChangeFormData: (data: FormDataInterface, id: string) => void;

  isEditTCPMedications: boolean;
  setIsEditTCPMedications: React.Dispatch<React.SetStateAction<boolean>>;
  organizationMedications: OrganizationMedicationType[];
}

export const usePatientFormFields = (props: UsePatientFormFieldsProps) => {
  const patientBasicForms: any = JSON.parse(JSON.stringify(PATIENT_BASIC_FORM_FIELDS));
  const vitalsForms: any = VITALS_FORM_FIELDS;

  if (props.enrolmentConfig.enrolmentConfig?.patientBasic) {
    const patientBasic = props?.enrolmentConfig?.enrolmentConfig?.patientBasic;

    Object.keys(patientBasic)?.forEach((key) => {
      if (PATIENT_BASIC_FORM_FIELDS[key] && patientBasic[key as keyof typeof patientBasic]) {
        patientBasicForms[key] = PATIENT_BASIC_FORM_FIELDS[key];
      } else {
        delete patientBasicForms[key];
      }
    });
  }

  if (props.enrolmentConfig.enrolmentConfig?.vitals) {
    const vitals = props?.enrolmentConfig?.enrolmentConfig?.vitals;

    Object.keys(vitals)?.forEach((key) => {
      if (VITALS_FORM_FIELDS[key] && vitals[key as keyof typeof vitals]) {
        vitalsForms[key] = VITALS_FORM_FIELDS[key];
      } else {
        delete vitalsForms[key];
      }
    });
  }

  const filteredSymptoms: { [key: string]: any } = {};
  const filterSymptomsData: { [key: string]: any } = {};
  for (const key in SYMPTOMS) {
    if (!IGNORE_SYMPTOMS.includes(key)) {
      filteredSymptoms[key] = SYMPTOMS[key];
    }
  }
  for (const key in SHOW_SYMPTOMS_DATA) {
    if (!IGNORE_SYMPTOMS.includes(key)) {
      filterSymptomsData[key] = SHOW_SYMPTOMS_DATA[key];
    }
  }

  const uiSchema = {
    physician: {
      'ui:ObjectFieldTemplate': GridTemplateObjectFieldTemplate,
      'ui:fields': {
        id: 'physician',
        disabled: props.editDetails?.physician,
        handleClick: () => props.handleClickEditDetails('physician'),
        customRef: props.tabPhysicianRef,
        className: 'physician',
      },

      assignPhysician: {
        'ui:widget': GridSelectInputField,
        'ui:customOptions': props.allDoctors.map((ele) => ({ ...ele, label: convertToPascalCase(ele.label, ' ', ' ') })),
        'ui:disabled': props.editDetails?.physician,
        'ui:required': true,
        'ui:grid': {
          xs: 3,
          lg: 3,
        },
      },
    },

    patientBasic: {
      'ui:order': [
        'enrollmentDate',
        'patientId',
        'firstName',
        'lastName',
        'age',
        'gender',
        'ethnicity',
        'address',
        'phoneNumber',
        'email',
        'phoneNumberAlt',
        'relationshipAltContact',
        'relationshipAltContactName',
      ],
      'ui:ObjectFieldTemplate': GridTemplateObjectFieldTemplate,
      'ui:fields': {
        id: 'patient-basic',
        disabled: props.editDetails?.basicDetails,
        handleClick: () => props.handleClickEditDetails('basicDetails'),
        customRef: props.tabPatientBasicDetailsRef,
        className: 'patient-basic',
      },

      enrollmentDate: {
        'ui:widget': GridInputField,
        'ui:type': 'date',
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:placeholder': 'Enter Date',
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:required': true,
        'ui:grid-area': 'enrollment-date',
        'ui:autoFocus': true,
      },

      patientId: {
        title: 'Patient ID',
        label: 'Patient ID',
        'ui:widget': GridInputField,
        'ui:required': true,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'patient-id',
      },

      firstName: {
        'ui:widget': GridInputField,
        'ui:required': true,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'first-name',
      },

      lastName: {
        'ui:widget': GridInputField,
        'ui:required': true,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'last-name',
      },

      gender: {
        'ui:widget': GridSelectInputField,
        'ui:required': true,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:customOptions': GENDER_OPTIONS,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'gender',
      },

      ethnicity: {
        'ui:widget': GridSelectInputField,
        'ui:required': true,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:customOptions': ETHNICITY_OPTIONS.filter((e) => !IGNORE_ETHNICITY_OPTIONS.includes(e.value)),
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'ethnicity',
      },

      age: {
        'ui:widget': GridInputField,
        'ui:required': true,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:type': 'number',
        // 'ui:placeholder': 'Enter Age',
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'birth-date',
      },

      phoneNumber: {
        'ui:widget': PhoneNumberInputField,
        'ui:required': true,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'phone-number',
      },

      email: {
        'ui:widget': GridInputField,
        'ui:required': true,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'email',
      },

      address: {
        'ui:widget': GridInputField,
        'ui:required': true,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:grid': {
          xs: 6,
        },
        'ui:grid-area': 'address',
      },

      phoneNumberAlt: {
        'ui:widget': PhoneNumberInputField,
        'ui:required': false,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'phone-number-alt',
      },

      relationshipAltContact: {
        'ui:widget': GridSelectInputField,
        'ui:required': false,
        'ui:customOptions': RELATIONSHIP_OF_ALT_CONTACT_OPTIONS,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'relationship-alt-contact',
      },

      relationshipAltContactName: {
        'ui:widget': GridInputField,
        'ui:required': false,
        'ui:disabled': props.editDetails?.basicDetails,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:grid-area': 'relationship-alt-contact-name',
      },

      TitleFieldTemplate,
      FieldErrorTemplate,
      'ui:maxErrors': 2,
    },

    medicalHistory: {
      'ui:ObjectFieldTemplate': GridTemplateObjectFieldTemplate,
      'ui:fields': {
        id: 'medical-history-grid-container',
        disabled: props.editDetails?.medicalHistory,
        handleClick: () => props.handleClickEditDetails('medicalHistory'),
        className: 'medical-history',
        customRef: props.tabPatientMedicalHistoryRef,
      },

      dateOfHFDiagnosis: {
        'ui:widget': GridInputField,
        'ui:disabled': props.editDetails?.medicalHistory,
        'ui:type': 'date',
        'ui:placeholder': 'Enter Date',
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:mandatory': true,
        'ui:grid-area': 'date-of-hf-diagnosis',
      },

      hfType: {
        'ui:widget': GridSelectInputField,
        'ui:disabled': props.editDetails?.medicalHistory,
        'ui:customOptions': HF_TYPES_OPTIONS,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:mandatory': true,
        'ui:grid-area': 'hf-type',
      },

      nyhaClass: {
        'ui:widget': GridSelectInputField,
        'ui:disabled': props.editDetails?.medicalHistory,
        'ui:customOptions': NYHA_OPTIONS,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:mandatory': true,
        'ui:grid-area': 'nyha-class',
      },

      lastHospitalizationDate: {
        'ui:widget': GridInputField,
        'ui:disabled': props.editDetails?.medicalHistory,
        'ui:grid': {
          xs: 6,
          lg: 3,
        },
        'ui:type': 'date',
        'ui:mandatory': true,
        'ui:placeholder': 'Enter Date',
        'ui:grid-area': 'last-hospitalization-date',
      },

      lvef: {
        'ui:widget': GridInputField,
        'ui:disabled': props.editDetails?.medicalHistory,
        'ui:type': 'number',
        'ui:endAdornment': '%',
        'ui:grid': {
          xs: 3,
        },
        'ui:mandatory': true,
        'ui:grid-area': 'lvef',
      },

      diseasesAndSymptoms: {
        'ui:ObjectFieldTemplate': GridTemplateObjectWithoutCard,
        'ui:grid-area': 'diseases-symptoms',
        'ui:fields': {
          className: 'diseases-symptoms',
        },

        cardiovascularDiseases: {
          'ui:ObjectFieldTemplate': GridColumnObjectTemplate,
          'ui:grid-area': 'cardio-vascular-diseases',
          'ui:noErrors': true,

          coronaryHeartDisease: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          valvularHeartDisease: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          typeIDiabetesMellitus: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          typeIIDiabetesMellitus: {
            'ui:widget': GridCheckBoxField,
            // 'ui:infoIcon': true,
            // 'ui:info': 'More than 37 million Americans have type II diabetes',
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          chronicKidneyDisease: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          atrialFibrillation: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          hypertension: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          peripheralArteryDisease: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          priorMyocardialInfarction: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          aorticPlaque: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          stroke: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          others: {
            'ui:widget': GridCheckBoxField,
            // 'ui:infoIcon': true,
            'ui:noErrors': true,
            // 'ui:info': 'Please capture details in the Notes / Contraindications for the patient.',
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          otherCardiovascularDiseases: {
            'ui:widget': GridTextareaField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
            'ui:grid': {
              xs: 12,
            },
            'ui:fields': {
              minRows: 5,
              maxRows: 5,
            },
          },
        },

        nonCardiovascularDiseases: {
          'ui:ObjectFieldTemplate': GridColumnObjectTemplate,
          'ui:grid-area': 'non-cardio-vascular-diseases',
          'ui:noErrors': true,

          copd: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          hypothyroidism: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          anaemia: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            // 'ui:infoIcon': true,
            'ui:noErrors': true,
            // 'ui:info': 'Patient can have anemia with or without iron deficiency',
          },

          ironDeficiency: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            // 'ui:infoIcon': true,
            'ui:noErrors': true,
            // 'ui:info': 'Patient can have iron deficiency with or without anemia',
          },

          obesity: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            // 'ui:infoIcon': true,
            'ui:noErrors': true,
            // 'ui:info': 'Patients with bmi above 30.0 are usually considered obese',
          },

          smoking: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          alcohol: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          dementia: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          others: {
            'ui:widget': GridCheckBoxField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
          },

          otherNonCardiovascularDiseases: {
            'ui:widget': GridTextareaField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:grid': {
              xs: 12,
            },
            'ui:fields': {
              minRows: 5,
              maxRows: 5,
            },
          },
        },

        symptoms: {
          'ui:ObjectFieldTemplate': GridColumnObjectTemplate,
          'ui:grid-area': 'symptoms',
          'ui:noErrors': true,

          noSymptoms: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          newFatigue: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          syncope: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          breathdDailyAct: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          breathRest: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          swellPeri: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          swellLower: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          swellAbd: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          dryCoughOne: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          dryCoughTwo: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          copd: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          newDizziness: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          swellChestOne: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          swellChestTwo: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          nausea: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          fatigue: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          shortness: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          shortnessOfBreath: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          dyspneaBreathlesson: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          dyspnea: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          orthopneaBreathless: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          orthopnea: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          paraoxysmal: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          paraoxysmalNocturnalDyspnea: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          wheezing: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          palpitation: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          palpitations: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },
          // increasedFatigue: {
          //   'ui:widget': GridCheckBoxField,
          //   'ui:disabled': props.editDetails?.medicalHistory,
          //   'ui:noErrors': true,
          // },

          // increasedBreathlessness: {
          //   'ui:widget': GridCheckBoxField,
          //   'ui:disabled': props.editDetails?.medicalHistory,
          //   'ui:noErrors': true,
          // },

          // pnd: {
          //   'ui:widget': GridCheckBoxField,
          //   'ui:disabled': props.editDetails?.medicalHistory,
          //   'ui:noErrors': true,
          // },

          dizziness: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          swellingHandsLegsFeet: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          swellingHandsFeet: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          swellingLipsEyes: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          // dryCough: {
          //   'ui:widget': GridCheckBoxField,
          //   'ui:disabled': props.editDetails?.medicalHistory,
          //   'ui:noErrors': true,
          // },

          dryCough: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          chestTightness: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          others: {
            'ui:widget': GridCheckBoxField,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noErrors': true,
          },

          otherSymptoms: {
            'ui:widget': GridTextareaField,
            'ui:noErrors': true,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:grid': {
              xs: 12,
            },
            'ui:fields': {
              minRows: 5,
              maxRows: 5,
            },
          },
        },
      },

      allergies: {
        'ui:widget': GridTextareaField,
        'ui:disabled': props.editDetails?.medicalHistory,
        'ui:grid': {
          xs: 6,
        },
        'ui:mandatory': false,
        'ui:grid-area': 'allergies',
        'ui:fields': {
          minRows: 4,
          maxRows: 4,
        },
      },

      vitalsReference: {
        'ui:ObjectFieldTemplate': GridColumnObjectTemplate,
        'ui:grid-area': 'vitals-and-parameters',
        'ui:noErrors': true,

        vitalsReferenceDate: {
          'ui:widget': GridInputField,
          'ui:type': 'datetime-local',
          'ui:disabled': props.editDetails?.medicalHistory,
          'ui:placeholder': 'Enter Date',
          'ui:grid': {
            xs: 3,
          },
          'ui:mandatory': true,
          'ui:grid-area': 'vitals-reference-date',
        },

        vitals: {
          'ui:ObjectFieldTemplate': FlexVitalsObjectFieldTemplate,
          'ui:withBorder': true,
          'ui:grid-area': 'vitals',
          'ui:noErrors': true,
          'ui:fields': {
            id: 'vitals-grid-container',
            disabled: props.editDetails?.medicalHistory,
            handleClick: () => props.handleClickEditDetails('medicalHistory'),
            className: 'vitals-grid',
          },

          bp: {
            'ui:ObjectFieldTemplate': FlexVitalsMultiInput,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.bloodPressure,
            'ui:grid-area': 'bp',
            'ui:mandatory': true,
            'ui:grid': {
              md: 12,
            },

            sbp: {
              'ui:widget': FlexInputField,
              'ui:disabled': props.editDetails?.medicalHistory,
              'ui:noLabel': false,
              'ui:endAdornment': <Typography variant='fontSemiBold12'>SYS</Typography>,
              'ui:type': 'number',
              'ui:grid': {
                xs: 7,
              },
              'ui:noErrors': true,
            },

            dbp: {
              'ui:widget': FlexInputField,
              'ui:disabled': props.editDetails?.medicalHistory,
              'ui:noLabel': false,
              'ui:type': 'number',
              'ui:endAdornment': <Typography variant='fontSemiBold12'>DIA</Typography>,
              'ui:grid': {
                xs: 6,
              },
              'ui:noErrors': true,
            },
          },

          bnp: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.ntprobnp,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'bnp',
          },

          glucose: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.glucose,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'glucose',
          },

          hr: {
            'ui:widget': FlexInputField,
            'ui:mandatory': true,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.heartRate,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'hr',
          },

          weight: {
            'ui:widget': FlexInputField,
            'ui:mandatory': true,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.weight,
            'ui:noLabel': true,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'weight',
          },

          height: {
            'ui:ObjectFieldTemplate': FlexVitalsMultiInput,
            'ui:mandatory': true,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.height,
            'ui:grid': {
              md: 12,
            },
            'ui:grid-area': 'height',

            feet: {
              'ui:widget': FlexInputField,
              'ui:disabled': props.editDetails?.medicalHistory,
              'ui:noLabel': false,
              'ui:endAdornment': <Typography variant='fontSemiBold12'>FT</Typography>,
              'ui:type': 'number',
              'ui:grid': {
                xs: 7,
              },
              'ui:noErrors': true,
            },

            inches: {
              'ui:widget': FlexInputField,
              'ui:mandatory': true,
              'ui:disabled': props.editDetails?.medicalHistory,
              'ui:noLabel': false,
              'ui:type': 'number',
              'ui:endAdornment': <Typography variant='fontSemiBold12'>IN</Typography>,
              'ui:grid': {
                xs: 6,
              },
              'ui:noErrors': true,
            },
          },

          bmi: {
            'ui:widget': FlexInputField,
            'ui:mandatory': true,
            'ui:infoIcon': true,
            'ui:info': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.weight.toLowerCase().includes('kgs') ? <BmiEquationWithKgs /> : <BmiEquation />,
            // 'ui:info': <BmiEquation />,
            'ui:unit': '',
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 9,
            },
            'ui:grid-area': 'bmi',
          },

          ntprobnp: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.ntprobnp,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'ntprobnp',
          },

          scr: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.sCr,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'scr',
          },

          egfr: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.eGFR,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'egfr',
          },

          potassium: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.potassium,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'potassium',
          },

          chloride: {
            'ui:widget': FlexInputField,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.chloride,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:mandatory': false,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'chloride',
          },

          calcium: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.calcium,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'calcium',
          },

          bloodUreaNitrogen: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.bloodUreaNitrogen,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'blood-urea-nitrogen',
          },

          hemoglobin: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.hemoglobin,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'hemoglobin',
          },

          hematocrit: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.hematocrit,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'hematocrit',
          },

          hba1c: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.hba1c,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'hba1c',
          },

          sodium: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.sodium,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'sodium',
          },

          bicarbonate: {
            'ui:widget': FlexInputField,
            'ui:mandatory': false,
            'ui:unit': props.enrolmentConfig?.vitalsUnitConfig?.vitalsUnit?.bicarbonate,
            'ui:disabled': props.editDetails?.medicalHistory,
            'ui:noLabel': true,
            'ui:type': 'number',
            'ui:grid': {
              md: 8,
            },
            'ui:grid-area': 'bicarbonate',
          },
        },
      },
    },

    previousMedications: {
      'ui:FieldTemplate': CustomTemplatePrevMedications,
      'ui:fields': {
        id: 'prev-medications-grid-container',
        disabled: props.editDetails?.prevMedications,
        handleClick: () => props.handleClickEditDetails('prevMedications'),
        className: 'prev-medications',
      },
    },
  };

  const schema: RJSFSchema = {
    title: 'Enroll Patient',
    description: '',
    type: 'object',
    properties: {
      physician: {
        title: 'Assign a Physician',
        type: 'object',
        properties: {
          assignPhysician: { type: 'string', title: 'Assign a Physician' },
        },
      },

      patientBasic: {
        title: 'Patient Personal Details',
        type: 'object',
        properties: {
          ...patientBasicForms,
        },
      },

      medicalHistory: {
        title: 'Medical History',
        type: 'object',
        properties: {
          dateOfHFDiagnosis: { type: 'string', title: 'HF First Diagnosed On', default: DateTime.now().toFormat('yyyy-MM-dd') },
          lvef: { type: 'number', title: 'LVEF', readOnly: true },
          hfType: { type: 'string', title: 'Type of HF', default: 'HFrEF' },
          nyhaClass: { type: 'string', title: 'NYHA Class' },
          lastHospitalizationDate: { type: 'string', title: 'Last Hospitalized On', default: DateTime.now().toFormat('yyyy-MM-dd') },

          diseasesAndSymptoms: {
            type: 'object',
            properties: {
              cardiovascularDiseases: {
                title: 'Cardiovascular Diseases',
                type: 'object',
                properties: CARDIOVASULCAR_DISEASES,

                allOf: [
                  {
                    if: {
                      type: 'object',
                      properties: {
                        others: {
                          const: true,
                        },
                      },
                    },

                    then: {
                      type: 'object',
                      properties: {
                        otherCardiovascularDiseases: { type: 'string', title: 'Other Cardiovascular Diseases' },
                      },
                    },
                  },
                ],
              },

              nonCardiovascularDiseases: {
                title: 'Non Cardiovascular Diseases',
                type: 'object',
                properties: {
                  ...NON_CARDIOVASCULAR_DISEASES,
                },

                allOf: [
                  {
                    if: {
                      type: 'object',
                      properties: {
                        others: {
                          const: true,
                        },
                      },
                    },

                    then: {
                      type: 'object',
                      properties: {
                        otherNonCardiovascularDiseases: { type: 'string', title: 'Other Non Cardiovascular Diseases' },
                      },
                    },
                  },
                ],
              },

              symptoms: {
                title: 'Symptoms',
                type: 'object',
                properties: { ...filteredSymptoms },

                allOf: [
                  {
                    if: {
                      type: 'object',
                      properties: {
                        others: {
                          const: true,
                        },
                      },
                    },

                    then: {
                      type: 'object',
                      properties: {
                        otherSymptoms: { type: 'string', title: 'Other Symptoms' },
                      },
                    },
                  },
                  {
                    if: {
                      type: 'object',
                      properties: {
                        noSymptoms: {
                          const: true,
                        },
                      },
                    },

                    then: {
                      type: 'object',
                      properties: { ...filterSymptomsData },
                    },
                  },
                ],
              },
            },
          },

          allergies: { type: 'string', title: 'Allergies' },

          vitalsReference: {
            type: 'object',
            title: 'Vitals / Parameters Reading',
            properties: {
              vitalsReferenceDate: { type: 'string', title: 'Date and Time of Entry', default: DateTime.now().toFormat('yyyy-MM-dd HH:mm') },
              vitals: {
                type: 'object',
                title: '',
                properties: { ...vitalsForms },
              },
            },
          },
        },
      },

      previousMedications: {
        title: 'Previous Medications',
        type: 'array',
      },
    },
  };

  return [schema, uiSchema];
};
