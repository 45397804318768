/** @format */

import React, { FunctionComponent } from 'react';
import * as _ from 'underscore';
import { Navigate, useLocation } from 'react-router-dom';
import CustomLoading from './components/common/custom-loading/CustomLoading';
import { getUserSession } from './utils/user-session';

export interface PrivateAuthProps {
  children: JSX.Element;
}
export interface OrganizationProductsRoleDto {
  roles: string[];
  productId: string;
}

export interface UserDto {
  id: number;
  userId: string;
  name: string;
  email: string;
  password: string;
  mobile: string;
  resetPasswordToken: string;
  resetPasswordTokenExpiresAt: string;
}

export interface OrganizationsDto {
  id: number;
  organizationId: string;
  name: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  latitude: string;
  longitude: string;
  organizationCode: string;
  status: 'ACTIVE' | 'DEACTIVATED';
}

export type UserSessionUserTypeDto = 'ORGANIZATION_USER' | 'SASS_ADMIN_USER';

export interface UserSessionType {
  sessionId: string;

  userId: string;
  user: UserDto;
  userType: UserSessionUserTypeDto;

  organization: OrganizationsDto;

  productRoles?: OrganizationProductsRoleDto[];
  productRole?: OrganizationProductsRoleDto;

  adminRoles: string[];
  owner: boolean;

  userIsPinSetup: boolean;
  isAcceptUserPolicy: boolean;

  status: 'ACTIVE' | 'DEACTIVATED';
}

const PrivateAuth: FunctionComponent<PrivateAuthProps> = (props) => {
  const location = useLocation();

  const [isMount, setIsMount] = React.useState<boolean>(false);
  const [auth, setAuth] = React.useState<UserSessionType>({} as UserSessionType);

  React.useEffect(() => {
    const userSession = getUserSession();
    setAuth(userSession);
    setIsMount(true);
  }, []);

  if (!isMount) {
    return <CustomLoading />;
  }

  if (_.isEmpty(auth.sessionId)) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return props.children;
};

PrivateAuth.defaultProps = {};

export default PrivateAuth;
