/** @format */

import AxiosInstance from './index';
import config from '../config/config';

export const uploadPatientAttachments = async (patientSourceId: string, file: any, type: string) => {
  const data: any = new FormData();
  data.append('file', file);
  data.append('type', type);
  const res = await AxiosInstance({
    method: 'post',
    baseURL: config.patientApiUrl,
    url: `/patients/${patientSourceId}/attachments`,
    data,
  });
  return res.data || {};
};

export const fetchPatientAttachments = async (patientSourceId: string, type: string, limit: number, offset: number) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: config.patientApiUrl,
    url: `/patients/${patientSourceId}/attachments`,
    params: {
      limit,
      type,
      offset,
    },
  });
  return res.data || [];
};

export const fetchPatientAttachmentsPresignedUrl = async (patientSourceId: string, attachmentId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: config.patientApiUrl,
    url: `/patients/${patientSourceId}/attachments/${attachmentId}/presigned-url`,
  });
  return res.data || [];
};

export const createPatientReport = async (patientSourceId: string, reportdetails: any) => {
  const res = await AxiosInstance({
    method: 'POST',
    baseURL: config.patientApiUrl,
    url: `/patients/${patientSourceId}/reports`,
    data: reportdetails,
  });
  return res.data || {};
};

export const updatePatientReport = async (patientSourceId: string, reportdetails: any, reportid: string) => {
  const res = await AxiosInstance({
    method: 'PATCH',
    baseURL: config.patientApiUrl,
    url: `/patients/${patientSourceId}/reports/${reportid}`,
    data: reportdetails,
  });
  return res.data || {};
};

export const deletePatientReport = async (patientSourceId: string, reportid: number) => {
  const res = await AxiosInstance({
    method: 'DELETE',
    baseURL: config.patientApiUrl,
    url: `/patients/${patientSourceId}/reports/${reportid}`,
  });
  return res.data || {};
};

export const getPatientAttachmentsReports = async (patientSourceId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: config.patientApiUrl,
    url: `/patients/${patientSourceId}/reports`,
  });

  return res.data || {};
};

export const downloadPatientAttachementById = async (patientSourceId: string, attachmentId: string) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: config.patientApiUrl,
    url: `/patients/${patientSourceId}/attachments/${attachmentId}/download`,
    responseType: 'blob',
  });
  return res.data || [];
};
