/** @format */

import * as React from 'react';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { PatientTimeLineType } from '../../../types/Timeline.types';

import {
  VITALS_BICARBONATE,
  VITALS_BLOOD_UREA_NITROGEN,
  VITALS_CALCIUM,
  VITALS_CHLORIDE,
  VITALS_EGFR,
  VITALS_GLUCOSE,
  VITALS_MAP_LABEL,
  VITALS_NTPROBNP,
  VITALS_POTASSIUM,
  VITALS_SCR,
  VITALS_SODIUM,
} from '../../../constants/PatientVitals';
import _ from 'underscore';
import { V2PatientAttachmentReportsPayloadReportType } from '../../../types/PatientAttachments.types';
import { Divider } from '@mui/material';

interface TimelineLabReportItemProps {
  data: PatientTimeLineType;
  lastTimelineItem: boolean;

  organiationUsers: Array<{ label: string; value: string }>;
}

const TimelineLabReportItem: React.FunctionComponent<TimelineLabReportItemProps> = (props) => {
  const labReprotTypes = [
    VITALS_GLUCOSE.value,
    VITALS_CALCIUM.value,
    VITALS_BLOOD_UREA_NITROGEN.value,
    VITALS_SODIUM.value,
    VITALS_BICARBONATE.value,
    VITALS_CHLORIDE.value,
    VITALS_POTASSIUM.value,
    VITALS_SCR.value,
    VITALS_EGFR.value,
    VITALS_NTPROBNP.value,
  ];

  if (!_.isEmpty(props?.data?.payload?.patientLabReports)) {
    return (
      <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
        <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
          <Typography variant='fontSemiBold12' color='#3E4685'>
            Results
          </Typography>
        </ListItem>
        {props?.data?.payload?.patientLabReports?.reports.map((item: V2PatientAttachmentReportsPayloadReportType, index: number) => {
          return (
            <ListItem key={index} sx={{ borderBottom: index !== props?.data?.payload?.vitals.length - 1 ? '1px solid #D5D5D6' : '', p: 2 }}>
              <Grid container justifyContent='center'>
                <Grid item xs={12}>
                  <Typography component='div' variant='fontSemiBold14' color='#3E4685'>
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item xs={11} container>
                  {item.tests?.map((item) => {
                    return (
                      <Grid item container xs={12} gap={2}>
                        <Grid item xs={9}>
                          <Typography variant='fontSemiBold12' color='#3E4685'>
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='fontBold16' color='#3E4685' sx={{ pl: 2 }}>
                            {item.value}
                          </Typography>
                          <Typography variant='fontBold16' color='#3E4685' sx={{ pl: 0.5 }}>
                            {item.units}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Divider />
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
      <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
        <Typography variant='fontSemiBold12' color='#3E4685'>
          Results
        </Typography>
      </ListItem>
      {props?.data?.payload?.vitals?.map((item, index) => {
        if (labReprotTypes.includes(item.type)) {
          return (
            <ListItem key={index} sx={{ borderBottom: index !== props?.data?.payload?.vitals.length - 1 ? '1px solid #D5D5D6' : '', p: 2 }}>
              <Typography variant='fontSemiBold14' color='#3E4685'>
                {VITALS_MAP_LABEL[item.type]} {'   '}({item.measurements?.[0].unit})
              </Typography>
              <Typography sx={{ ml: 'auto' }} variant='fontSemiBold16' color='#3E4685'>
                {item.measurements?.[0].value}
              </Typography>
            </ListItem>
          );
        }
        return null;
      })}
    </List>
  );
};

export default TimelineLabReportItem;
