/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import { DialogTitle } from '@mui/material';
// import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import SchedulePatientAppointmentForm from './SchedulePatientAppointmentForm';
import PatientSearchDynamicDimensions from './PatientSearchDynamicDimensions';

import { AppointmentsType } from '../../types/Appointments';
import { useParams } from 'react-router-dom';
import { PatientProfileContext } from '../../pages/patient-profile/PatientProfileContext';

interface SearchSchedulePatientAppointmentModalProps {
  open: boolean;

  handleClose: () => void;

  isNewAppointment?: boolean;

  isUpdatingAppointment: boolean;

  disableButtons: boolean;

  appointment?: AppointmentsType;

  handleDeleteAppointment: (appointment: AppointmentsType) => void;

  handleSubmitAppointment: (payload: AppointmentsType) => void;

  selectedPatientInfo?: any;

  isForceEdit?: boolean;

  isRescheduleModal?: boolean;
}

const SearchSchedulePatientAppointmentModal: React.FunctionComponent<SearchSchedulePatientAppointmentModalProps> = (props) => {
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

  const [selectedPatientInfo, setSelectedPatientInfo] = React.useState(null);

  const { patientSourceId } = useParams();

  const { patientInfo } = React.useContext(PatientProfileContext);

  let userDetails: {
    firstName: string;
    lastName: string;
    patientId: string;
    patientSourceId: string;
    age: string;
    gender: string;
  } | null = null;

  if (patientSourceId) {
    userDetails = {
      firstName: patientInfo.basicDetails?.firstName || '',
      lastName: patientInfo.basicDetails?.lastName || '',
      patientId: patientInfo.basicDetails?.patientId || '',
      patientSourceId: patientSourceId!,
      age: patientInfo.basicDetails?.age as string,
      gender: patientInfo.basicDetails?.gender as string,
    };
  }

  const handleChangePatientInfo = (payload: any) => {
    setSelectedPatientInfo(payload);
  };

  const handleClickClearPatientSearchFilter = () => {
    setSelectedPatientInfo(null);
  };

  const handleSubmitAppointment = (payload: AppointmentsType) => {
    props.handleSubmitAppointment({
      ...payload,
      patientDetails: { ...(selectedPatientInfo as any) },
    });
  };

  React.useEffect(() => {
    setIsEditMode(!!props.isNewAppointment);
  }, [props.isNewAppointment]);

  React.useEffect(() => {
    if (props.isForceEdit) {
      setIsEditMode(true);
    }
  }, [props.isForceEdit]);

  React.useEffect(() => {
    if (!_.isEmpty(props.selectedPatientInfo)) {
      setSelectedPatientInfo(props.selectedPatientInfo);
    }
  }, [props.selectedPatientInfo]);

  const toggleEditMode = () => {
    setIsEditMode((prev) => !prev);
  };

  const handleClickCanelBtn = () => {
    if (props.isNewAppointment || props.isRescheduleModal) {
      return props.handleClose();
    }
    toggleEditMode();
  };

  return (
    <Dialog onClose={props.handleClose} fullWidth open={props.open} maxWidth={'xs'}>
      <DialogTitle sx={{ backgroundColor: '#F1F2F3', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 15px' }}>
        <Typography variant='fontBold16' color='primary'>
          Meeting Details
        </Typography>

        <IconButton onClick={props.handleClose} sx={{ marginLeft: 'auto', padding: 0 }}>
          <CloseIcon color='primary' />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ px: 1 }}>
        <Grid container px={2.5} gap={2} mt={2} gridTemplateColumns={'1fr 1fr'} justifyContent={'space-between'}>
          <Grid item>
            <Typography variant='fontReg18' color='primary'>
              Patient details
            </Typography>
          </Grid>
          <Grid item>
            <PatientSearchDynamicDimensions
              userDetails={patientSourceId ? userDetails : selectedPatientInfo}
              disabled={!_.isEmpty(props.selectedPatientInfo) || !_.isEmpty(patientSourceId)}
              handleChange={handleChangePatientInfo}
              handleClickClearSearchFilter={handleClickClearPatientSearchFilter}
              width={195}
              height={60}
              isNewAppointment={props?.isNewAppointment!}
            />
          </Grid>
        </Grid>

        <SchedulePatientAppointmentForm
          isUpdatingAppointment={props.isUpdatingAppointment}
          disableButtons={props.disableButtons || _.isEmpty(selectedPatientInfo || userDetails)}
          isEditMode={isEditMode}
          handleClickEditBtn={toggleEditMode}
          appointment={props.appointment}
          handleClickCanelBtn={handleClickCanelBtn}
          handleSubmitAppointment={handleSubmitAppointment}
          handleDeleteAppointment={props.handleDeleteAppointment}
          selectedPatientInfo={selectedPatientInfo}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SearchSchedulePatientAppointmentModal;
