/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { PatientAlreadyPrescribedMedicationsType } from '../../../types/PrescriptionsGenerator.types';

import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

import AlgoSuggestionViewToolTip, { getAlgoSuggestionBasedOnSelected } from '../AlgoSuggestionViewToolTip';

interface PrescribedMedicationsProps {}

const PrescribedMedications: React.FunctionComponent<PrescribedMedicationsProps> = () => {
  const {
    isEditPatientPrescriptions,

    patientApprovalPrescriptions,

    patientAlreadyPrescribedMedications,
    handleClickOnAddOrCancelPrescribedMedications,
    handleClickOnStopOrCancelPrescribedMedications,
  } = React.useContext(PatientProfileContext);

  const handleClickAllMedications = () => {
    patientAlreadyPrescribedMedications.forEach((item) => {
      handleClickOnAddOrCancelPrescribedMedications(item!, true);
    });
  };

  const _renderPrescribedMedicationCard = (item: PatientAlreadyPrescribedMedicationsType) => {
    const { reviewSuggestion, algoSuggestion } = getAlgoSuggestionBasedOnSelected(item, patientApprovalPrescriptions);

    return (
      <Paper
        elevation={0}
        key={item.brandName}
        sx={{
          padding: '10px',
          borderRadius: '8px',
          backgroundColor: '#F2F4F7',
          marginBottom: '10px',
          '&:hover': {
            backgroundColor: '#EFF1FF',
            '.buttons': {
              opacity: 1,
            },
          },
        }}>
        <Grid item xs={12} container alignItems='center' justifyContent='space-between' gap={1}>
          <Grid item xs={5}>
            <Typography
              variant='fontReg14'
              color='#33425B'
              component='div'
              sx={{
                mt: 0.5,
                marginRight: '5px',
                display: 'inline-block',
                // width: '180px',
                wordBreak: 'break-word',
                whiteSpace: 'break-spaces',
                overflow: 'auto !important',
              }}>
              {item?.brandName}
            </Typography>
            <Typography
              variant='fontReg12'
              color='#33425B'
              component='div'
              sx={{
                mt: 0.5,
                marginRight: '5px',
                display: 'inline-block',
                // width: '180px',
                whiteSpace: 'break-spaces',
                overflow: 'auto !important',
                textOverflow: 'ellipsis',
              }}>
              {item?.medicationNameDisplayName || item?.medicationName}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant='fontReg14' color='#33425B' component='div'>
              {item?.duration} {item?.durationUnits}
            </Typography>
            <Typography variant='fontReg12' color='#5C6A90' component='div'>
              {item?.frequencyRegime}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <AlgoSuggestionViewToolTip algoSuggestion={algoSuggestion}>
              <Typography variant='fontReg14' color='#707070' component='span'>
                {reviewSuggestion}
              </Typography>
            </AlgoSuggestionViewToolTip>
          </Grid>

          <Grid
            item
            xs={2}
            className='buttons'
            container
            sx={{
              visibility: isEditPatientPrescriptions ? 'none' : 'hidden',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
              opacity: item.addToNewPrescriptions || item.stopInNewPrescriptions ? 1 : 0,
            }}>
            {item.addToNewPrescriptions && <Chip label='Added' variant='outlined' sx={{ borderRadius: '5px' }} />}

            {!item.stopInNewPrescriptions && !item.addToNewPrescriptions && (
              <Button variant='outlined' size='small' color='primary' onClick={() => handleClickOnAddOrCancelPrescribedMedications(item!, true)}>
                Add
              </Button>
            )}

            {item.addToNewPrescriptions && (
              <Button sx={{ mt: 1 }} variant='contained' size='small' color='error' onClick={() => handleClickOnAddOrCancelPrescribedMedications(item!, false)}>
                Cancel
              </Button>
            )}

            {item.stopInNewPrescriptions && <Chip label='Stopped' variant='outlined' sx={{ borderRadius: '5px' }} />}

            {!item.stopInNewPrescriptions && !item.addToNewPrescriptions && (
              <Button sx={{ mt: 1 }} variant='contained' size='small' color='error' onClick={() => handleClickOnStopOrCancelPrescribedMedications(item!, true)}>
                Stop
              </Button>
            )}

            {item.stopInNewPrescriptions && (
              <Button sx={{ mt: 1 }} variant='contained' size='small' color='error' onClick={() => handleClickOnStopOrCancelPrescribedMedications(item!, false)}>
                Cancel
              </Button>
            )}
          </Grid>
        </Grid>
        {item.isStopMedications && (
          <React.Fragment>
            <Divider />
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography variant='fontReg14' color='#D80914' component='div'>
                Stopped
              </Typography>
            </Grid>
          </React.Fragment>
        )}
      </Paper>
    );
  };

  if (_.isEmpty(patientAlreadyPrescribedMedications)) {
    return (
      <Typography variant='fontSemiBold14' color='#3E4685' component='div' sx={{ width: '100%', textAlign: 'center', mt: 3 }}>
        No Previously Prescribed Medications
      </Typography>
    );
  }

  return (
    <Box sx={{ paddingRight: '10px' }}>
      <Box>
        <Button disabled={!isEditPatientPrescriptions} variant='outlined' onClick={handleClickAllMedications}>
          <Typography variant='fontReg14' color='#3E4685'>
            Add All
          </Typography>
        </Button>
      </Box>

      <Grid item xs={12} container alignItems='center' justifyContent='space-between' gap={1} sx={{ my: 2 }}>
        <Grid item xs={5}>
          <Typography variant='fontReg14' color='#33425B' component='div'>
            Name
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant='fontReg14' color='#33425B' component='div'>
            Duration
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant='fontReg14' color='#33425B' component='span'>
            Suggestion
          </Typography>
        </Grid>

        <Grid item xs={2} container></Grid>
      </Grid>

      {patientAlreadyPrescribedMedications.map(_renderPrescribedMedicationCard)}
    </Box>
  );
};

export default PrescribedMedications;
