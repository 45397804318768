/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import AddReport from './AddReport';
import { V2PatientAttachmentReportType } from '../../../types/PatientAttachments.types';
import { NEW_ATTACHMENT_REPORT_ID } from '../../../constants/PatientAttachments';
import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';
import CustomLoading from '../../common/custom-loading/CustomLoading';

const Attachments: React.FunctionComponent = () => {
  const { patientAttachmentsReportsData, handleDeletePatientReport: handleDeleteReport, refetchPatientDetails, isLoadingPatientAttachmentsReports } = React.useContext(PatientProfileContext);

  const [attachmentReports, setAttachmnetReports] = React.useState<V2PatientAttachmentReportType[]>([]);

  const handleClickDeleteAttachmentReport = (attachmentReport: V2PatientAttachmentReportType) => {
    if (attachmentReport.attachmentReportId === NEW_ATTACHMENT_REPORT_ID) {
      setAttachmnetReports((item) => item.filter((ele) => ele.attachmentReportId !== NEW_ATTACHMENT_REPORT_ID));
    }

    handleDeleteReport(attachmentReport.reportId);
  };

  const handleClickNewReportType = (type: string) => {
    setAttachmnetReports((prev) => {
      const isNew = prev.find((item) => item.attachmentReportId === NEW_ATTACHMENT_REPORT_ID);
      if (!_.isEmpty(isNew)) {
        return prev.map((item) => {
          if (item.attachmentReportId === NEW_ATTACHMENT_REPORT_ID) {
            return { ...item, type } as V2PatientAttachmentReportType;
          }
          return item;
        });
      }

      return [{ type, attachmentReportId: NEW_ATTACHMENT_REPORT_ID } as V2PatientAttachmentReportType].concat(prev);
    });
  };

  const handleClickCancelAttachmentReport = (payload: V2PatientAttachmentReportType) => {
    const filterItems = attachmentReports.filter((e, i) => e.id !== payload.id);
    setAttachmnetReports(filterItems);
    refetchPatientDetails.refetch();
  };

  if (isLoadingPatientAttachmentsReports) {
    return <CustomLoading />;
  }

  const reportActionOption = [
    {
      title: 'Add report',
      onClick: () => {
        handleClickNewReportType('REPORTS');
      },
    },
    {
      title: 'Add prescription',
      onClick: () => {
        handleClickNewReportType('PRESCRIPTIONS');
      },
    },
    {
      title: 'Add other',
      onClick: () => {
        handleClickNewReportType('OTHERS');
      },
    },
  ];

  const _renderReportActionButtons = (items: any, index: number, array: any[]) => {
    return (
      <Button
        key={index}
        sx={{
          boxShadow: 'none',
          border: '1px solid #9BB4CC',
          borderRadius: '5px',
          height: '32px',
          padding: '0 10px',
          '.MuiInput-input': {
            padding: 0,
          },
          marginTop: '10px',
          ml: index !== 0 ? 1 : 0,
          mr: index !== array?.length - 1 ? 0 : 1,
        }}
        onClick={items.onClick}
        startIcon={<AddIcon sx={{ color: 'grey' }} />}>
        <Typography>{items.title}</Typography>
      </Button>
    );
  };

  return (
    <Box>
      <Grid item xs={12} gap={3} sx={{ marginBottom: '15px' }} justifyContent='space-between'>
        {reportActionOption.map(_renderReportActionButtons)}
      </Grid>

      <Grid item xs={12} container gap={1}>
        {attachmentReports &&
          attachmentReports.map((item, index) => (
            <Grid item xs={12}>
              <AddReport
                key={index}
                handleClickCancelAttachmentReport={handleClickCancelAttachmentReport}
                attachmentReport={item}
                setAttachmnetReports={setAttachmnetReports}
                attachmentReports={attachmentReports}
                handleClickDeleteAttachmentReport={handleClickDeleteAttachmentReport}
              />
            </Grid>
          ))}

        {patientAttachmentsReportsData &&
          patientAttachmentsReportsData.map((item, index) => (
            <Grid item xs={12}>
              <AddReport
                key={index}
                attachmentReport={item}
                setAttachmnetReports={setAttachmnetReports}
                attachmentReports={attachmentReports}
                handleClickCancelAttachmentReport={handleClickCancelAttachmentReport}
                handleClickDeleteAttachmentReport={handleClickDeleteAttachmentReport}
              />
            </Grid>
          ))}
      </Grid>

      {attachmentReports?.length <= 0 && patientAttachmentsReportsData?.length <= 0 && (
        <Typography component='div' textAlign='center'>
          No Reports
        </Typography>
      )}
    </Box>
  );
};
export default Attachments;
